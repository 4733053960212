import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import UsePostData from "../../../../../hooks/api/PostHook";
import useFetch from "../../../../../hooks/api/fetchHook";
import { useSelector } from "react-redux";
import {
  updateSale,
  fetchAllSales,
} from "../../../../../store/slices/SaleSlice";
import { useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import Loading from "../../../../../hooks/utils/Loading/Loading";

const AddEditPage = () => {
  const location = useLocation();
  const [id, setId] = useState(null);
  const fileInputs = useRef({});

  const [formData, setFormData] = useState({
    _id: '',
    createdAt: new Date().toLocaleDateString("en-GB"),
    type: "",
    reason: "",
    accountName: "",
    servicesAddress: "",
    billingAddress: "",
    primaryContact: "",
    altContact: "",
    purposeOfCall: "",
    currentProvider: "",
    accountType: "",
    accountBalance: "",
    pastDue: "",
    accountNumber: "",
    securityCode: "",
    dueDate: "",
    nameOnCard: "",
    cardNo: "",
    cardType: "",
    last4Digits: "",
    cvv: "",
    expiryCard: "",
    authorizedAmount: "",
    dob: "",
    ssn: "",
    status: "",
    receipt: "",
    paymentProcessor: "",
    paidOver: "",
    callBack: "",
    paymentConfirmationNumber: "",
    billingTeamComments: "",
  });

  const base_url = `${process.env.REACT_APP_BASE_URL}`;
  const role = useSelector((state) => state?.auth?.userRole);
  const [isBillingSame, setIsBillingSame] = useState(false);
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state?.auth);
  const { sales, status, error } = useSelector((state) => state.sales);

  const path = location.pathname;
  const showFields = path.includes("edit-sale")
    ? role === "admin" || role === "manager"
      ? true
      : false
    : true;

  const fetchOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const idFromQuery = queryParams.get("id");
    setId(idFromQuery);
  }, [location]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${base_url}/api/sales/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          });
          let data = await response.json();
          data.data.createdAt = new Date(
            data?.data?.createdAt
          ).toLocaleDateString("en-GB");
          data.data.dueDate = new Date(data?.data?.dueDate)
            .toISOString()
            .slice(0, 10);
          data.data.dob = new Date(data?.data?.dob).toISOString().slice(0, 10);
          const updatedFields = Object.keys(formData).reduce((acc, key) => {
            if (key in data.data) {
              acc[key] = data.data[key];
            } else {
              acc[key] = formData[key]; // Preserve the default value if not in data
            }
            return acc;
          }, {});
          
          setFormData(updatedFields);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData(); // Fetch data only once when id is available
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "servicesAddress" && isBillingSame) {
      setFormData((prevData) => ({
        ...prevData,
        billingAddress: value,
      }));
    }
  };

  const { apiData } = useFetch(`${base_url}/api/fields`, fetchOptions);

  useEffect(() => {
    dispatch(fetchAllSales({ page: 1, limit: 10, filter: {} }));
  }, [dispatch]);

  useEffect(() => {
    if (status === "succeeded") {
    } else if (status === "failed") {
      console.error("Error fetching sales data:", error);
    }
  }, [sales, status, error]);

  const inputData = [
    {
      type: "text",
      status: "inputs",
      label: "Date",
      name: "createdAt",
    },
    {
      type: "text",
      status: "inputs",
      label: "Account Name",
      name: "accountName",
    },
    {
      type: "text",
      status: "inputs",
      label: "Services Address",
      name: "servicesAddress",
    },
    {
      type: "text",
      status: "inputs",
      label: "Billing Address",
      name: "billingAddress",
    },
    {
      type: "number",
      status: "inputs",
      label: "Primary Contact",
      name: "primaryContact",
    },
    {
      type: "number",
      status: "inputs",
      label: "Alt Contact",
      name: "altContact",
    },
    {
      status: "selectors",
      label: "Purpose Of Call",
      name: "purposeOfCall",
      optionValue: "PurposeOfCall",
    },
    {
      status: "selectors",
      label: "Current Provider",
      name: "currentProvider",
      optionValue: "CurrentProvider",
    },
    ...(showFields
      ? [
          {
            status: "selectors",
            label: "Account Type",
            name: "accountType",
            optionValue: "AccountType",
          },
          {
            type: "number",
            status: "inputs",
            label: "Account Balance $",
            name: "accountBalance",
          },
        ]
      : []),
    {
      type: "number",
      status: "inputs",
      label: "Past Due",
      name: "pastDue",
    },
    ...(showFields
      ? [
          {
            type: "number",
            status: "inputs",
            label: "Account Number",
            name: "accountNumber",
          },
          {
            type: "number",
            status: "inputs",
            label: "Security Code",
            name: "securityCode",
          },
        ]
      : []),
    {
      type: "date",
      status: "inputs",
      label: "Due Date",
      name: "dueDate",
    },
    ...(showFields
      ? [
          {
            type: "text",
            status: "inputs",
            label: "Name On Card",
            name: "nameOnCard",
          },
          {
            status: "selectors",
            label: "Card Type",
            name: "cardType",
            optionValue: "CardType",
          },
          {
            type: "text",
            status: "inputs",
            label: "Card Number",
            name: "cardNo",
          },
        ]
      : []),
    {
      type: "number",
      status: "inputs",
      label: "Last 4 Digits",
      name: "last4Digits",
    },
    ...(showFields
      ? [
          {
            type: "number",
            status: "inputs",
            label: "CVV",
            name: "cvv",
          },
          {
            type: "text",
            status: "inputs",
            label: "Card Expiry Date",
            name: "expiryCard",
          },
        ]
      : []),
    {
      type: "number",
      status: "inputs",
      label: "Authorized Amount",
      name: "authorizedAmount",
    },
    {
      type: "date",
      status: "inputs",
      label: "DOB",
      name: "dob",
    },
    {
      type: "text",
      status: "inputs",
      label: "SSN",
      name: "ssn",
    },
    {
      status: "selectors",
      label: "Status",
      name: "status",
      optionValue: "Status",
    },
    {
      status: "selectors",
      label: "Payment Processor",
      name: "paymentProcessor",
      optionValue: "PaymentProcessor",
    },
    {
      status: "selectors",
      label: "Paid Over",
      name: "paidOver",
      optionValue: "PaidOver",
    },
    {
      status: "selectors",
      label: "Call Back",
      name: "callBack",
      optionValue: "CallBack",
    },
    {
      type: "text",
      status: "inputs",
      label: "Payment Confirmation Number",
      name: "paymentConfirmationNumber",
    },
    {
      type: "text",
      status: "inputs",
      label: "Billing Team Comments",
      name: "billingTeamComments",
    },
    {
      status: "imageUpload",
      label: "Receipt",
      name: "receipt",
    },
  ];

  useEffect(() => {
    if (apiData?.data) {
      const filteredOptions = inputData
        .filter((item) => item.status === "selectors" && item.optionValue)
        .map((item) => ({
          name: item.name,
          options: apiData.data
            .filter((field) => field.type === item.optionValue)
            .map((field) => ({ value: field._id, label: field.name })),
        }));

      const updatedOptions = filteredOptions.reduce((acc, curr) => {
        acc[curr.name] = curr.options;
        return acc;
      }, {});

      // Only update the state if the new options differ from the current options
      setOptions((prevOptions) => {
        const optionsChanged =
          JSON.stringify(prevOptions) !== JSON.stringify(updatedOptions);
        if (optionsChanged) {
          return updatedOptions;
        }
        return prevOptions; // Do not update if nothing has changed
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData?.data]); // Add proper dependencies

  const handleChange = (selectedOption, { name }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption?.label || "", // Update to store label
    }));
  };

  const handleImageUpload = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, [fieldName]: file });
    }
  };

  const handleImagePaste = (e, fieldName) => {
    const items = e.clipboardData.items;
    for (const item of items) {
      if (item.type.includes("image")) {
        const file = item.getAsFile();
        setFormData({ ...formData, [fieldName]: file });
      }
    }
  };

  const handleRemoveImage = (fieldName) => {
    setFormData({ ...formData, [fieldName]: null });
    if (fileInputs.current[fieldName]) {
      fileInputs.current[fieldName].value = "";
    }
  };

  const { postData } = UsePostData(`${base_url}/api/sales`);

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    let result;
    let data = new FormData();

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (formData[key] instanceof File || formData[key] instanceof Blob) {
          data.append(key, formData[key]);
        } else {
          if (formData[key]) {
            data.append(key, formData[key]);
          }
        }
      }
    }

    // If updating an existing sale (PATCH request)
    if (path.includes("edit-sale")) {
      data.delete("employeeId");
      data.delete("followUp");
      result = await dispatch(updateSale(data));
      toast.success("Sale Updated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
    }

    // If creating a new sale (POST request)
    if (path.includes("create-sale")) {
      result = await postData(null, data); // Use the postData hook inside the component
    }
    if (result?.status === "success" || result?.payload?.status === "success") {
      setLoading(false);
      navigate("/sales");
      toast.success("Sale Added Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
    }
    if (result?.status === "success" || result?.payload?.status === "success") {
      navigate("/sales");
    } else {
      setLoading(false);
      toast.error(`Submission failed: ${result?.payload?.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const handleCheckboxChange = () => {
    setIsBillingSame(!isBillingSame);
    if (!isBillingSame) {
      setFormData((prevData) => ({
        ...prevData,
        billingAddress: formData.servicesAddress,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        billingAddress: "",
      }));
    }
  };

  const handleFilterByCardNumber = () => {
    const cardNumber = formData.cardNo?.trim();
    const today = new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);

    if (cardNumber) {
      const filtered = sales.filter((sale) => {
        const createdAtDate = new Date(sale.createdAt);
        return sale.cardNo === cardNumber && createdAtDate >= thirtyDaysAgo;
      });

      if (filtered.length > 0) {
        toast.info(
          <div>
            <div>Sale Number: {filtered[0]?._id || "N/A"}</div>
            <div>Account Name: {filtered[0]?.accountName || "N/A"}</div>
            <div>
              Date and Time:{" "}
              {new Date(filtered[0]?.createdAt).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              }) || "N/A"}
            </div>
            <div>Sale Status: {filtered[0]?.status || "N/A"}</div>
          </div>,
          {
            position: "top-center",
          }
        );
      } else {
        toast.error("No sales found with this card number in the last 30 days");
      }
    } else {
      toast.error("Please enter a card number to search");
    }
  };

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div>
            <div className="row d-flex">
              {inputData.map((item, index) => (
                <div key={index} className="col-md-3 col-sm-6">
                  <div className="input-block mb-3">
                    {item.status === "inputs" ? (
                      <div>
                        <label htmlFor={item.name} className="col-form-label">
                          {item.label}
                        </label>
                        <div className="input-group">
                          <input
                            type={item.type}
                            className="form-control"
                            name={item.name}
                            value={formData[item.name] || ""}
                            onChange={handleInputChange}
                            disabled={
                              (item.name === "billingAddress" &&
                                isBillingSame) ||
                              item.name === "createdAt"
                            }
                          />
                          {item.name === "cardNo" && (
                            <button
                              className="btn btn-outline-secondary"
                              onClick={handleFilterByCardNumber}
                            >
                              <FaSearch />
                            </button>
                          )}
                        </div>
                        {item.name === "billingAddress" && (
                          <div className="mt-2">
                            <input
                              type="checkbox"
                              id="billingCheckbox"
                              checked={isBillingSame}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="billingCheckbox" className="ms-2">
                              Same as Services Address
                            </label>
                          </div>
                        )}
                      </div>
                    ) : item.status === "selectors" ? (
                      <div>
                        <label
                          htmlFor={item.name}
                          className="pb-1 col-form-label"
                        >
                          {item.label}
                        </label>
                        <div className="custom-select-container">
                          <Select
                            options={options[item.name] || []}
                            value={options[item.name]?.find(
                              (option) => option.label === formData[item.name]
                            )}
                            onChange={(selectedOption) =>
                              handleChange(selectedOption, item)
                            }
                            className="form-control p-0"
                            name={item.name}
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: "38px",
                              }),
                              menu: (base) => ({
                                ...base,
                                width: "100%",
                              }),
                            }}
                          />
                        </div>
                      </div>
                    ) : item.status === "imageUpload" ? (
                      <div>
                        <label htmlFor={item.name} className="form-label">
                          {item.label}
                        </label>
                        <div className="mb-3">
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(e) => handleImageUpload(e, item.name)}
                            ref={(input) =>
                              input && (fileInputs.current[item.name] = input)
                            } // Store file input ref
                            disabled={!!formData[item.name]} // Disable file input if there's an image already
                          />
                        </div>
                        <div
                          className={`border mt-2 p-3 rounded ${
                            formData[item.name] ? "disabled" : ""
                          }`}
                          onPaste={(e) =>
                            !formData[item.name] &&
                            handleImagePaste(e, item.name)
                          }
                          style={{
                            border: "1px solid #ced4da",
                            minHeight: "150px",
                            backgroundColor: formData[item.name]
                              ? "#f8f9fa"
                              : "transparent",
                          }}
                        >
                          {formData[item.name] ? (
                            <img
                              src={
                                formData[item.name] instanceof File
                                  ? URL.createObjectURL(formData[item.name])
                                  : formData[item.name]
                              } // Use URL.createObjectURL to display the file
                              alt="Uploaded content"
                              className="img-fluid"
                            />
                          ) : (
                            <span className="text-muted">
                              Paste an image here
                            </span>
                          )}
                        </div>
                        {formData[item.name] && (
                          <button
                            className="btn btn-danger mt-2"
                            onClick={() => handleRemoveImage(item.name)}
                          >
                            Remove Image
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="submit-section">
            <button
              className="btn btn-primary submit-btn"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditPage;
