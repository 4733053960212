import React, { useState, useEffect } from "react";
import Select from "react-select";
import useFetch from "../../hooks/api/fetchHook";
import { useSelector } from "react-redux";

const AddDesignationModelPopup = ({
  onSubmit,
  onEdit,
  editDesignation,
  mode,
}) => {
  const { userToken } = useSelector((state) => state?.auth);

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/departments`,
    options
  );

  const designationOptions = apiData?.data?.departments
    ? apiData.data.departments.map((department) => ({
        value: department._id,
        label: department.name,
      }))
    : [{ value: 1, label: "Select Department" }];

  const [description, setDescription] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  // Pre-fill or reset fields based on mode
  useEffect(() => {
    if (mode === "edit" && editDesignation) {
      setDesignationName(editDesignation?.title);
      setDescription(editDesignation?.description);
      setSelectedDepartment({
        value: editDesignation?.department,
        label: editDesignation?.department,
      });
    } else {
      setDesignationName("");
      setDescription("");
      setSelectedDepartment(null);
    }
  }, [mode, editDesignation]);

  // const customStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
  //     color: state.isFocused ? "#fff" : "#000",
  //     "&:hover": {
  //       backgroundColor: "#ff9b44",
  //     },
  //   }),
  // };
  const dropdownStyle = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#cb6ce6"
        : state.isFocused
        ? "#f8defff5"
        : undefined,
      color: state.isSelected ? "#fff" : undefined,
      "&:active": {
        backgroundColor: "#f8defff5",
      },
    }),
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      title: designationName,
      department: selectedDepartment.value,
      description,
    };

    if (mode === "edit") {
      await onEdit(payload); // Call edit function
    } else {
      onSubmit(payload);
    }

    const modalId = "edit_designation";
    const modalElement = document.getElementById(modalId);
    const closeButton = modalElement?.querySelector(".btn-close");
    if (closeButton) closeButton.click(); // Simulate the close button click
    // Reset form fields
    setDesignationName("");
    setDescription("");
    setSelectedDepartment(null);
  };

  return (
    <>
      {/* Modal ID based on mode */}
      <div
        id="edit_designation"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {mode === "edit" ? "Edit Designation" : "Add Designation"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Designation Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={designationName}
                    onChange={(e) => setDesignationName(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Department"
                    styles={dropdownStyle}
                    required
                    options={designationOptions}
                    value={selectedDepartment}
                    onChange={(option) => setSelectedDepartment(option)}
                  />
                </div>

                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDesignationModelPopup;
