import { useState } from "react";
import { useSelector } from "react-redux";

function UsePutData(url) {
  const [isLoading, setIsLoading] = useState(false);
  const bearerToken = useSelector((state) => state?.auth?.userToken);
  const putData = async (data) => {
    setIsLoading(true);

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json(); 
      if (!result.status === "success") {
        return result.message || "Failed to put data";
      }

      return result;
    } catch (error) {
      return error.message;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, putData };
}

export default UsePutData;
