import React, { useEffect, useState } from "react";
import { Table, Pagination } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllLeaves,
  approveLeave,
  rejectLeave,
  deleteLeave,
} from "../../../store/slices/LeaveSlice";
import Select from "react-select";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Error404 from "../../../hooks/utils/Error/Error";
import Loading from "../../../hooks/utils/Loading/Loading";

const AdminLeave = () => {
  const dispatch = useDispatch();
  const { leaves, status, total } = useSelector((state) => state.leave);
  const userInfo = useSelector((state) => state.auth.userInfo);

  const [selectedLeave, setSelectedLeave] = useState(null);
  const [filterStatus, setFilterStatus] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const role = useSelector((state) => state?.auth?.userRole);

  const handleReload = () => {
    dispatch(
      fetchAllLeaves({
        pagination: {
          page: currentPage,
          limit: pageSize,
          status: filterStatus === "All" ? undefined : filterStatus,
        },
      })
    );
  };

  const buttonStyles = {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    fontSize: "16px",
    color: "#007bff",
    backgroundColor: "#f8f9fa",
    border: "2px solid #007bff",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const hoverStyles = {
    backgroundColor: "#007bff",
    color: "#fff",
  };

  const handleMouseEnter = (e) => {
    Object.assign(e.currentTarget.style, hoverStyles);
  };

  const handleMouseLeave = (e) => {
    Object.assign(e.currentTarget.style, buttonStyles);
  };

  useEffect(() => {
    dispatch(
      fetchAllLeaves({
        page: currentPage,
        limit: pageSize,
        status: filterStatus === "All" ? undefined : filterStatus,
      })
    );
  }, [dispatch, currentPage, pageSize, filterStatus]);

  useEffect(() => {
    const filtered = leaves.filter((leave) => {
      if (filterStatus === "All") return true;
      return leave.status === filterStatus;
    });
    setFilteredLeaves(filtered);
  }, [leaves, filterStatus]);

  const handleApproveLeave = async (id) => {
    try {
      await dispatch(approveLeave({ id, approvedBy: userInfo.id })).unwrap();
      handleReload();
    } catch (error) {
      console.error("Error approving leave:", error);
    }
  };

  const handleRejectLeave = async (id) => {
    try {
      await dispatch(rejectLeave({ id, rejectedBy: userInfo.id })).unwrap();
      handleReload();
    } catch (error) {
      console.error("Error rejecting leave:", error);
    }
  };

  const handleDeleteLeaveClick = (id) => {
    setSelectedLeave(id);
  };

  const confirmDeleteLeave = () => {
    if (!selectedLeave) return;
    dispatch(deleteLeave(selectedLeave));
    setSelectedLeave(null);
  };

  const closeDeleteModal = () => {
    setSelectedLeave(null);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["userId", "firstName"],
      render: (text, record) =>
        `${record?.userId?.firstName} ${record?.userId?.lastName}`,
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
    },
    {
      title: "From",
      dataIndex: "startDate",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
      sorter: (a, b) => {
        const today = new Date();
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);

        const diffA = dateA - today;
        const diffB = dateB - today;

        if (diffA >= 0 && diffB >= 0) return diffA - diffB;

        if (diffA < 0 && diffB < 0) return diffA - diffB;

        return diffA >= 0 ? -1 : 1;
      },
    },
    {
      title: "To",
      dataIndex: "endDate",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Status",
      dataIndex: "status",
      render:
        userInfo?.role === "hr"
          ? (text, record) => (
              <div className="dropdown action-label text-start">
                <a
                  href="/****"
                  className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    className={
                      text === "New"
                        ? "far fa-dot-circle text-purple"
                        : text === "Pending"
                        ? "far fa-dot-circle text-info"
                        : text === "Approved"
                        ? "far fa-dot-circle text-success"
                        : "far fa-dot-circle text-danger"
                    }
                  />
                  {text}
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <span
                    className="dropdown-item"
                    onClick={() => handleApproveLeave(record._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="far fa-dot-circle text-success" /> Approve
                  </span>
                  <span
                    className="dropdown-item"
                    onClick={() => handleRejectLeave(record._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="far fa-dot-circle text-danger" /> Reject
                  </span>
                </div>
              </div>
            )
          : undefined,
    },
    ...(userInfo?.role === "hr"
      ? [
          {
            title: "Action",
            render: (text, record) => (
              <div className="dropdown dropdown-action text-center text-danger">
                <a
                  href="/****"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#delete"
                  onClick={() => handleDeleteLeaveClick(record._id)}
                >
                  <i className="fa fa-trash m-r-5 text-danger" />
                </a>
              </div>
            ),
          },
        ]
      : []),
  ];

  const statusOptions = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Leaves"
            title="Main"
            subtitle="Employees"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row mb-3 d-flex justify-content-between">
            <div className="col-md-3">
              {/* <Select
                options={statusOptions}
                defaultValue={statusOptions[0]}
                onChange={(option) => setFilterStatus(option.value)}
                placeholder="Filter by Status"
              /> */}
              <Select
                options={statusOptions}
                defaultValue={statusOptions[0]}
                onChange={(option) => setFilterStatus(option.value)}
                placeholder="Filter by Status"
              />
            </div>
            {role === "admin" ? (
              <div className="col-md-2 d-flex align-items-center justify-content-end">
                <button
                  onClick={handleReload}
                  className="reload-button"
                  style={buttonStyles}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="la la-sync"></i>
                </button>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <Table
                  columns={columns}
                  // dataSource={leaves}
                  dataSource={filteredLeaves}
                  rowKey={(record) => record._id}
                  pagination={false}
                  locale={{
                    emptyText:
                      status === "loading" ? (
                        <Loading />
                      ) : status === "failed" ? (
                        <div className="text-center text-danger">
                          <Error404 />
                        </div>
                      ) : (
                        "No Data"
                      ),
                  }}
                />
                <div
                  className="mt-4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={total || 0}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={["10", "25", "50", "100"]}
                    showQuickJumper
                    showTotal={(total) => `Total ${total} leaves`}
                    itemRender={(page, type, originalElement) => {
                      if (type === "prev") {
                        return <span>Previous</span>;
                      }
                      if (type === "next") {
                        return <span>Next</span>;
                      }
                      return originalElement;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteModal
        onDeleteLeave={confirmDeleteLeave}
        closeModal={closeDeleteModal}
      />
    </>
  );
};

export default AdminLeave;
