import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Applogo, Avatar_02 } from "../../Routes/ImagePath";
import headerlogo from "../../assets/img/zolaralogowhite.png";
import { useLocation } from "react-router-dom/dist";
import { logout } from "../../store/slices/AuthSlice";
import { fetchUserDetails } from "../../store/slices/UserSlice";
import UsePostData from "../../hooks/api/PostHook";

const Header = (props) => {
  const [profile, setProfile] = useState(false);

  const dispatch = useDispatch(); 
  const navigate = useNavigate(); 

  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const userId = useSelector((state) => state?.auth?.userInfo?.id);
  const role = useSelector((state) => state?.auth?.userRole);

  const { userDetails } = useSelector((state) => state?.user);

  useEffect(() => {
    if (isLoggedIn && userId) {
      dispatch(fetchUserDetails(userId));
    }
  }, [dispatch, isLoggedIn, userId]);

  const { firstName, lastName, name, image } = userDetails?.data?.user || {};

  const displayName =
    firstName && lastName ? `${firstName} ${lastName}` : name ? name : "Admin";

  const displayImage = image ? image : Avatar_02;

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    props.setSidebarExpanded(!props.isSidebarExpanded);
  };
  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };

  const handleProfile = () => {
    setProfile(!profile);
  };
  const { postData: postPunchOut } = UsePostData(
    `${process.env.REACT_APP_BASE_URL}/api/attendance/punch-out`
  );

  const handleLogout = () => {
    const punchOutData = {
      userId: userId,
    };

    postPunchOut(punchOutData);
    dispatch(logout());
    navigate("/"); 
  };

  const location = useLocation();
  let pathname = location.pathname;

  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <div
        className="header-left"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          to={role === "employee" ? "/attendance-employee" : "/employees"}
          className="logo"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ maxWidth: "170px", height: "auto", }}
              src={headerlogo}
              alt="img"
            />
          </div>
        </Link>
        <Link
          to={role === "employee" ? "/attendance-employee" : "/employees"}
          className="logo2"
        >
          <img src={Applogo} width={40} height={40} alt="img" />
        </Link>
      </div>
      <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
            ? "none"
            : "",
        }}
        onClick={handlesidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>

      <Link
        id="mobile_btn"
        className="mobile_btn"
        to="#"
        onClick={() => onMenuClik()}
      >
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      <ul className="nav user-menu">

        <li className="nav-item dropdown has-arrow main-drop">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleProfile}
          >
            {" "}
            <span className="user-img me-1">
              <img src={displayImage} alt="img" />
              <span className="status online" />
            </span>
            <span>{displayName}</span>
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end ${
              profile ? "show" : ""
            }`}
          >
            <Link className="dropdown-item" to="/profile">
              My Profile
            </Link>
            <Link className="dropdown-item" to="/" onClick={handleLogout}>
              Logout
            </Link>
          </div>
        </li>
      </ul>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu">
        <Link
          to="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </Link>
        <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
          <Link className="dropdown-item" to="/profile">
            My Profile
          </Link>
          <Link className="dropdown-item" to="/" onClick={handleLogout}>
            Logout
          </Link>
        </div>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Header;
