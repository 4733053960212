import React, { useEffect, useState } from "react";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import AddBidModalPopup from "../../../components/modelpopup/AddBidModalPopup";
import SearchBox from "../../../components/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAllBids,
  createBid,
  updateBid,
  deleteBid,
} from "../../../store/slices/UpworkSlice";
import { toast } from "react-toastify";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import ActionMenu from "./ActionsMenu";
import Breadcrumbs from "../../../components/Breadcrumbs";

const Bids = () => {
  const [selectedBid, setSelectedBid] = useState(null);
  const [mode, setMode] = useState(null);
  const dispatch = useDispatch();
  const { bids, status, error } = useSelector((state) => state?.upwork || {});

  useEffect(() => {
    dispatch(fetchAllBids()); // Fetch all bids on component mount
  }, [dispatch]);

  // Handle adding a bid
  const handleAddBid = async (bidData) => {
    try {
      await dispatch(createBid(bidData)).unwrap();
      await dispatch(fetchAllBids()); // Refetch bids to ensure up-to-date data
      toast.success("Bid added successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add bid");
    }
  };

  // Handle updating a bid
  const handleUpdateBid = async ({ id, bidData }) => {
    try {
      await dispatch(updateBid({ id, bidData })).unwrap();
      await dispatch(fetchAllBids()); // Refetch bids to ensure up-to-date data
      toast.success("Bid updated successfully");
      resetSelection();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update bid");
    }
  };

  // Handle deleting a bid
  const handleDelete = async () => {
    if (selectedBid) {
      try {
        await dispatch(deleteBid(selectedBid._id)).unwrap();
        await dispatch(fetchAllBids()); // Refetch bids to ensure up-to-date data
        toast.success("Bid deleted successfully");
        resetSelection();
      } catch (error) {
        toast.error(error.response?.data?.message || "Failed to delete bid");
      }
    }
  };

  // Handle editing a bid
  const handleEditBid = (bid) => {
    setSelectedBid(bid);
    setMode("edit");
  };

  // Handle deleting a bid
  const handleDeleteBid = (bid) => {
    setSelectedBid(bid);
    document.getElementById("delete").click();
  };

  const resetSelection = () => {
    setSelectedBid(null);
    setMode(null);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Bids"
            title="Dashboard"
            subtitle="Bids"
            modal="#edit"
            name="Add Bid"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns(handleEditBid, handleDeleteBid)}
                  dataSource={prepareBidsForDisplay(bids)}
                  className="table-striped"
                  rowKey={(record) => record._id}
                  locale={getTableLocale(status, error)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddBidModalPopup
        onSubmit={handleAddBid}
        onEdit={handleUpdateBid}
        editBid={selectedBid}
        mode={mode}
      />
      <DeleteModal Name="Delete Bid" onDeleteLeave={handleDelete} />
    </div>
  );
};

export default Bids;

// Helper to format bids for display
const prepareBidsForDisplay = (bids) => {
  return bids.map((bid, index) => ({
    key: index + 1,
    id: index + 1,
    ...bid,
    bidderName: `${bid.bidder?.firstName || ""} ${bid.bidder?.lastName || ""}`,
    responseDisplay: bid.response ? "Yes" : "No",
  }));
};

// Table columns
const columns = (handleEditBid, handleDeleteBid) => [
  { title: "#", dataIndex: "id", width: "5%" },
  { title: "Upwork ID", dataIndex: "upworkId", width: "15%" },
  { title: "Bidder", dataIndex: "bidderName", width: "20%" },
  { title: "Job Title", dataIndex: "jobTitle", width: "20%" },
  { title: "Job Posting Link", dataIndex: "jobPostingLink", width: "25%" },
  { title: "Connects Used", dataIndex: "connectNumber", width: "10%" },
  { title: "Client Response", dataIndex: "responseDisplay", width: "10%" },
  {
    title: "Action",
    className: "text-end",
    render: (_, record) => (
      <ActionMenu
        record={record}
        onEdit={handleEditBid}
        onDelete={handleDeleteBid}
      />
    ),
    width: "15%",
  },
];

// Utility for table locale (loading, errors, empty)
const getTableLocale = (status, error) => {
  return {
    emptyText: status?.fetchAllBids === "loading" ? (
      <Loading />
    ) : error?.fetchAllBids ? (
      <div className="text-center text-danger">
        <Error404 />
      </div>
    ) : (
      "No Data"
    ),
  };
};
