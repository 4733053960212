import React, { useState } from "react";
import { Link } from "react-router-dom";
import Salary from "../../../../../assets/json/employeeSalary";
import { Table } from "antd";
import EditSalaryModal from "../../../../../components/modelpopup/EditSalaryModal";
import DeleteModal from "../../../../../components/modelpopup/deletePopup";

const SalaryTable = () => {
  const data = Salary.Salary;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const totalPages = Math.ceil(data.length / pageSize);

  const startRow = (currentPage - 1) * pageSize + 1;
  const endRow = Math.min(currentPage * pageSize, data.length);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(parseInt(value));
    setCurrentPage(1);
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar d-flex align-items-center">
          <Link to="/profile" className="avatar">
            <img alt="" src={record.avatar} />
          </Link>
          <Link
            to="/profile"
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "16px",
              lineHeight: "20px",
            }}
            className="mt-1"
          >
            {text} <span style={{ fontSize: "11px" }}>{record.position}</span>
          </Link>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      sorter: (a, b) => a.employeeId.length - b.employeeId.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Join Date",
      dataIndex: "joiningDate",
      sorter: (a, b) => a.joiningDate.length - b.joiningDate.length,
    },
    {
      title: "Role",
      dataIndex: "roles",
      render: (text) => (
        <div className="dropdown">
          <Link
            to="#"
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {text}
          </Link>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to="#">
              Software Engineer
            </Link>
            <Link className="dropdown-item" to="#">
              Software Tester
            </Link>
            <Link className="dropdown-item" to="#">
              Frontend Developer
            </Link>
            <Link className="dropdown-item" to="#">
              UI/UX Developer
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Salary",
      dataIndex: "salary",
      render: (text) => <span>${text}</span>,
      sorter: (a, b) => a.salary.length - b.salary.length,
    },
    {
      title: "Payslip",
      render: () => (
        <Link className="btn btn-sm btn-primary" to="/salary-view">
          Generate Slip
        </Link>
      ),
    },
    {
      title: "Action",
      render: () => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_salary"
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <div>
              <span>Show</span>
              <select
                value={pageSize}
                onChange={(e) => handlePageSizeChange(e.target.value)}
                style={{ width: "50px" }}
                className="fs-6 mx-1 mb-3 py-1"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
              </select>

              <span>entries</span>
            </div>
            <Table
              className="table-striped"
              style={{ overflowX: "auto" }}
              columns={columns}
              dataSource={data.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
              )}
              rowKey={(record) => record.id}
              pagination={false}
            />
          </div>
        </div>
      </div>

      <div
        className="pagination-controls mt-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        <div>
          {`Showing ${startRow} to ${endRow} of ${data.length} entries`}
        </div>
        <div>
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className="previous-button"
            style={{
              fontSize: "16px",
              padding: "5px 14px",
              border: "1px solid gray",
              background: "#FFFFFF",
              color: "#666666",
              borderRadius: "4px",
            }}
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            style={{
              marginLeft: "10px",
              fontSize: "16px",
              padding: "5px 14px",
              border: "1px solid gray",
              background: "#FFFFFF",
              color: "#666666",
              borderRadius: "4px",
            }}
          >
            Next
          </button>
        </div>
      </div>

      <EditSalaryModal />
      <DeleteModal Name="Delete Salary" />
    </>
  );
};

export default SalaryTable;
