import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import AddDesingnationModelPopup from "../../../components/modelpopup/AddDesingnationModelPopup";
import SearchBox from "../../../components/SearchBox";
import useFetch from "../../../hooks/api/fetchHook";
import { useSelector, useDispatch } from "react-redux";
import Error404 from "../../../hooks/utils/Error/Error";
import Loading from "../../../hooks/utils/Loading/Loading";
import {
  updateDesignations,
  deleteDesignations,
} from "../../../store/slices/EmployeeSlice";
import UsePostData from "../../../hooks/api/PostHook";
import { toast } from "react-toastify";

const Designation = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [mode, setMode] = useState();
  const { userToken, userRole } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };
  const { apiData, isError, isLoading } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/designations`,
    options,
    refreshData
  );
  useEffect(() => {
    if (apiData?.data?.designations) {
      setUsers(apiData.data.designations);
      setPagination((prev) => ({
        ...prev,
        current: 1,
      }));
    }
  }, [apiData]);

  const userElements = users?.map((user, index) => ({
    key: index,
    id: index + 1,
    _id: user?._id,
    department: user?.department?.name,
    description: user.description,
    title: user.title,
  }));
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      width: "10%",
    },
    {
      title: "Designation",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: "40%",
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.localeCompare(b.department),
      width: "40%",
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: "40%",
    },
    ...(userRole === "hr" || userRole === "admin"
      ? [
          {
            title: "Action",
            className: "text-end",
            render: (_, record) => (
              <div className="dropdown dropdown-action text-end">
                <Link
                  to="#"
                  className="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="material-icons">more_vert</i>
                </Link>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_designation"
                    onClick={() => handleEditDesignation(record)}
                  >
                    <i className="fa fa-pencil m-r-5" /> Edit
                  </Link>
                  <Link
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#delete"
                    onClick={() => handleDeleteDesignation(record)}
                  >
                    <i className="fa fa-trash m-r-5" /> Delete
                  </Link>
                </div>
              </div>
            ),
            width: "10%",
          },
        ]
      : []),
  ];

  const { postData } = UsePostData(
    `${process.env.REACT_APP_BASE_URL}/api/designations`
  );

  const handleAddDesignation = async (designationData) => {
    setLoading(true);
    const result = await postData(designationData);
    setLoading(false);
    if (result.status === "success") {
      toast.success("Designation added successfully");
      setRefreshData((prev) => !prev);
    } else {
      toast.error(`Failed ${result.message}`);
    }
  };

  const handleEditDesignation = (designation) => {
    setSelectedDesignation(designation);
    setMode("edit");
  };

  const handleUpdateDesignation = async (designationData) => {
    const { title, description, department } = designationData;
    setLoading(true);
    if (selectedDesignation) {
      await dispatch(
        updateDesignations({
          id: selectedDesignation._id,
          title,
          description,
          department,
        })
      );
      setLoading(false);
      setSelectedDesignation(null);
      setMode("edit");
      toast.success("Designation updated successfully");
      setRefreshData((prev) => !prev);
    }
  };

  const handleDeleteDesignation = (designation) => {
    setSelectedDesignation(designation);
  };

  const handleDelete = () => {
    if (selectedDesignation) {
      setLoading(true);
      dispatch(deleteDesignations(selectedDesignation._id))
        .then(() => {
          setRefreshData((prev) => !prev);
        })
        .catch((error) => {
          toast.error(error.message || "Failed to delete department:");
        });
    }
  };

  const handleTableChange = (paginationConfig) => {
    setPagination({
      current: paginationConfig.current,
      pageSize: paginationConfig.pageSize,
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Designations"
            title="Main"
            subtitle="Designations"
            modal="#edit_designation"
            name="Add Designation"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive mb-3">
                <div>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={userElements}
                      className="table-striped"
                      rowKey={(record) => record._id || record.id}
                      pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "25", "50", "100"],
                      }}
                      onChange={(paginationConfig) => {
                        handleTableChange(paginationConfig);
                      }}
                      locale={{
                        emptyText: isError ? (
                          <div className="text-center text-danger">
                            <Error404 />
                          </div>
                        ) : (
                          "No Data"
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddDesingnationModelPopup
        onSubmit={handleAddDesignation}
        onEdit={handleUpdateDesignation}
        editDesignation={selectedDesignation}
        mode={mode}
      />
      <DeleteModal Name="Delete Designation" onDeleteLeave={handleDelete} />
    </div>
  );
};

export default Designation;
