export function objectToQueryParams(obj, parentKey = '') {
    const queryString = Object.keys(obj)
        .map((key) => {
            const fullKey = parentKey ? `${parentKey}[${key}]` : key;
            const value = obj[key];

            if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
                // Recursively handle nested objects
                return objectToQueryParams(value, fullKey);
            } else {
                // Encode key-value pairs
                return `${encodeURIComponent(fullKey)}=${encodeURIComponent(
                    value instanceof Date ? value.toISOString() : value
                )}`;
            }
        })
        .join('&');
    return queryString;
}
