import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import useFetch from "../../../../../hooks/api/fetchHook";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import EstimatesTable from "./estimatesTable";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import { fetchAllEmployees } from "../../../../../store/slices/EmployeeSlice";

const Estimates = () => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state?.auth);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [last4Digits, setLast4Digits] = useState("");
  const [focusedTwo, setFocusedTwo] = useState(false);
  const [salesAssociate, setSalesAssociate] = useState("");
  const [followupAssociate, setFollowUpAssociate] = useState("");

  const [filter, setFilter] = useState({});

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleDateChangeTwo = (date) => {
    setEndDate(date);
  };

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/fields`,
    options
  );

  const typeOptions = apiData?.data
    ? Array.from(new Set(apiData.data.map((item) => item.type))).map(
        (type) => ({
          value: type,
          label: type,
        })
      )
    : [];

  const usersData = useSelector((state) => state?.employee?.allEmployees || []);

  useEffect(() => {
    dispatch(fetchAllEmployees({}));
  }, [dispatch]);

  const usersOptions = usersData
    .filter((user) => user?.department?.type === "sales")
    .map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName}`,
    }));

  const followupOptions = usersData
    .filter((user) => user?.department?.type === "followup")
    .map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName}`,
    }));

  const nameOptions = selectedType
    ? apiData?.data
        .filter((item) => item.type === selectedType.value)
        .map((item) => ({ value: item.name, label: item.name }))
    : [];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "50px",
      borderRadius: "4px",
      borderColor: state.isFocused ? "#ff9b44" : provided.borderColor,
      paddingTop: "10px",
      "&:hover": {
        borderColor: "#ff9b44",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  function toCamelCase(str) {
    if (!str) return ""; // Handle empty strings or null values
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  const handleSearch = () => {
    const filter = {
      ...(startDate ? { startDate: new Date(startDate) } : {}),
      ...(endDate ? { endDate: new Date(endDate) } : {}),
      ...(selectedType?.value
        ? { [toCamelCase(selectedType.value)]: selectedOption?.value ?? "" }
        : {}),
      ...(salesAssociate ? { employeeId: salesAssociate } : {}),
      ...(followupAssociate ? { assignedTo: followupAssociate } : {}),
      ...(last4Digits ? { last4Digits: last4Digits } : {}),
    };

    setFilter(filter);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Sales"
            title="Main"
            subtitle="Sales"
            modal="#"
            name="Create Sales"
            link="/create-sale"
            modalClass="col-auto float-end ms-auto"
          />

          <div className="row filter-row">
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${
                  startDate ? "focused" : ""
                }`}
              >
                <div className="cal-icon focused">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={startDate}
                    onChange={handleDateChange}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">From</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${
                  endDate ? "focused" : ""
                }`}
              >
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={endDate}
                    onChange={handleDateChangeTwo}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">To</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Follow Up Associates"
                  onChange={(user) => setFollowUpAssociate(user.value)}
                  options={followupOptions}
                  styles={customStyles}
                />
                <label className="focus-label">Follow Up Associate</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Sales Associates"
                  onChange={(user) => setSalesAssociate(user.value)}
                  options={usersOptions}
                  styles={customStyles}
                />
                <label className="focus-label">Sales Associate</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Type"
                  onChange={setSelectedType}
                  options={typeOptions}
                  styles={customStyles}
                />
                <label className="focus-label">Type</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Option"
                  onChange={setSelectedOption}
                  options={nameOptions}
                  styles={customStyles}
                  isDisabled={!selectedType}
                />
                <label className="focus-label">Options</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={
                  focusedTwo || last4Digits !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={last4Digits}
                  onFocus={() => setFocusedTwo(true)}
                  onBlur={() => setFocusedTwo(last4Digits !== "")}
                  onChange={(e) => setLast4Digits(e.target.value)}
                />
                <label
                  className="focus-label"
                  onClick={() => setFocusedTwo(true)}
                >
                  last 4 Digits
                </label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <Link
                className="btn btn-success btn-block w-100"
                onClick={handleSearch}
              >
                Search
              </Link>
            </div>
          </div>
          <div>
            <EstimatesTable filter={filter} />
          </div>
        </div>
      </div>
      <DeleteModal Name="Delete Estimate" />
    </>
  );
};

export default Estimates;
