import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

const EmployeeLeaveModelPopup = ({ leave, closeModal, onSubmit }) => {
  const [leaveType, setLeaveType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reason, setReason] = useState("");
  const userId = useSelector(
    (state) => state?.user?.userDetails?.data?.user._id
  );

  useEffect(() => {
    if (leave) {
      setLeaveType(leave.leaveType);
      setStartDate(new Date(leave.startDate));
      setEndDate(new Date(leave.endDate));
      setReason(leave.reason);
    }
  }, [leave]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const leaveData = {
      userId,
      leaveType,
      startDate,
      endDate,
      reason,
    };
    onSubmit(leaveData);
  };

  const leaveOptions = [
    { value: "Sick", label: "Sick" },
    { value: "Casual", label: "Casual" },
    { value: "Other", label: "Other" },
  ];

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <div
      id="edit_leave"
      className="modal custom-modal fade show"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {leave ? "Edit Leave" : "Add Leave"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Leave Type <span className="text-danger">*</span>
                </label>
                <Select
                  options={leaveOptions}
                  value={leaveOptions.find(
                    (option) => option.value === leaveType
                  )}
                  onChange={(option) => setLeaveType(option.value)}
                  placeholder="Select Leave Type"
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  From <span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control datetimepicker"
                  dateFormat="dd-MM-yyyy"
                  minDate={tomorrow}
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  To <span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control datetimepicker"
                  dateFormat="dd-MM-yyyy"
                  minDate={startDate ? new Date(startDate.getTime()) : tomorrow}
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Reason <span className="text-danger">*</span>
                </label>
                <textarea
                  rows={4}
                  className="form-control"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
              <div className="submit-section">
                <button className="btn btn-primary submit-btn" type="submit">
                  {leave ? "Update Leave" : "Submit Leave Request"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeLeaveModelPopup;
