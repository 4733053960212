// store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/UserSlice";
import leaveReducer from "./slices/LeaveSlice";
import employeeReducer from "./slices/EmployeeSlice";
import authReducer from "./slices/AuthSlice";
import AssetsSlice from "./slices/AssetsSlice";
import HolidaySlice from "./slices/HolidaySlice";
import SaleSlice from "./slices/SaleSlice";
import CallSlice from "./slices/CallSlice";
import UpworkSlice from "./slices/UpworkSlice";
const persistConfig = {
  key: "auth",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    upwork: UpworkSlice,
    user: userReducer,
    leave: leaveReducer,
    auth: persistedAuthReducer,
    employee: employeeReducer, 
    assets: AssetsSlice,
    holidays: HolidaySlice,
    sales: SaleSlice,
    calls: CallSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
