import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash, faTimes  } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify"; 
import { fetchUserDetails } from "../../store/slices/UserSlice";// Assuming you're using react-toastify for notifications

const ChangePassword = ({ profileInfo, token, userId, dispatch }) => {
  const [passData, setPassData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [strength, setStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(false);

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassData({ ...passData, [name]: value });

    if (name === "newPassword") {
      checkStrength(value);
      setPasswordMatch(value === passData.confirmPassword);
    }

    if (name === "confirmPassword") {
      setPasswordMatch(value === passData.newPassword);
    }
  };

  const checkStrength = (password) => {
    let strengthValue = 0;

    // Lower and Uppercase check
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      strengthValue += 1;
    }

    // Number check
    if (password.match(/([0-9])/)) {
      strengthValue += 1;
    }

    // Special Character check
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strengthValue += 1;
    }

    // Length check (at least 8 characters)
    if (password.length > 7) {
      strengthValue += 1;
    }

    setStrength(strengthValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the current password
    if (!profileInfo || passData.currentPassword !== profileInfo.password) {
      return toast.error("Current password is incorrect");
    }

    // Validate new password and confirmation match
    if (passData.newPassword !== passData.confirmPassword) {
      return toast.error("New password and confirm password do not match");
    }

    // Check password strength
    if (!isStrongPassword(passData.newPassword)) {
      return toast.error("Password is not strong enough");
    }

    const data = {
      password: passData.newPassword,
    };

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${userId}`,
        data,
        config
      );

      if (response.status === 200) {
        dispatch(fetchUserDetails(userId));
        toast.success("Password updated successfully!");
      }
    } catch (error) {
      console.error(`Error updating password:`, error.response ? error.response.data : error.message);
      toast.error("Failed to update password. Please try again.");
    }
  };

  const isStrongPassword = (password) => {
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return strongPasswordRegex.test(password);
  };

  return (
    <div id="popover-password" style={{width: "70%", margin: "auto"}}>
      <form onSubmit={handleSubmit}>
        {/* Current Password */}
        <div className="form-group">
          <label htmlFor="currentPassword">Current Password</label>
          <div className="input-group">
            <input
              id="currentPassword"
              name="currentPassword"
              type={showPassword.currentPassword ? "text" : "password"}
              className="form-control"
              value={passData.currentPassword}
              onChange={handlePasswordChange}
              placeholder="Enter your current password"
              required
            />
            <span className="input-group-text" onClick={() => togglePasswordVisibility('currentPassword')}>
              <FontAwesomeIcon icon={showPassword.currentPassword ? faEyeSlash : faEye} />
            </span>
          </div>
        </div>

        {/* New Password */}
        <div className="form-group mt-4">
          <label htmlFor="newPassword">New Password</label>
          <div className="input-group">
            <input
              id="newPassword"
              name="newPassword"
              type={showPassword.newPassword ? "text" : "password"}
              className="form-control"
              value={passData.newPassword}
              onChange={handlePasswordChange}
              placeholder="Enter your new password"
              required
            />
            <span className="input-group-text" onClick={() => togglePasswordVisibility('newPassword')}>
              <FontAwesomeIcon icon={showPassword.newPassword ? faEyeSlash : faEye} />
            </span>
          </div>

          {/* Password Strength Bar */}
          <div className="progress mt-2" style={{height: "5px"}}>
            <div
              id="password-strength"
              className={`progress-bar ${
                strength < 2 ? "bg-danger" : strength === 3 ? "bg-warning" : "bg-success"
              }`}
              role="progressbar"
              style={{ width: `${(strength / 4) * 100}%` }}
            ></div>
          </div>

          {/* Password Strength Checklist */}
          <ul className="list-unstyled mt-2">
            <li>
              <span className="low-upper-case">
                <FontAwesomeIcon icon={strength >= 1 ? faCheck : faTimes } className="me-2"  style={{color: strength >= 1 ? "green" : "red"  }}/>
                Lowercase & Uppercase
              </span>
            </li>
            <li>
              <span className="one-number">
                <FontAwesomeIcon icon={strength >= 2 ? faCheck : faTimes } className="me-2"  style={{color: strength >= 2 ? "green" : "red"  }}/>
                Number (0-9)
              </span>
            </li>
            <li>
              <span className="one-special-char">
                <FontAwesomeIcon icon={strength >= 3 ? faCheck : faTimes } className="me-2"  style={{color: strength >= 3 ? "green" : "red"  }}/>
                Special Character (!@#$%^&*)
              </span>
            </li>
            <li>
              <span className="eight-character">
                <FontAwesomeIcon icon={strength >= 4 ? faCheck : faTimes } className="me-2"  style={{color: strength >= 4 ? "green" : "red"  }}/>
                At least 8 Characters
              </span>
            </li>
          </ul>
        </div>

        {/* Confirm Password */}
        <div className="form-group mt-4">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <div className="input-group">
            <input
              id="confirmPassword"
              name="confirmPassword"
              type={showPassword.confirmPassword ? "text" : "password"}
              className="form-control"
              value={passData.confirmPassword}
              onChange={handlePasswordChange}
              placeholder="Confirm your new password"
              required
            />
            <span className="input-group-text" onClick={() => togglePasswordVisibility('confirmPassword')}>
              <FontAwesomeIcon icon={showPassword.confirmPassword ? faEyeSlash : faEye} />
            </span>
          </div>
          {passData.confirmPassword && !passwordMatch && (
            <p className="text-danger mt-2">Passwords do not match!</p>
          )}
        </div>

        {/* Submit Button */}
        <div className="submit-section mt-4">
          <button className="btn btn-primary" type="submit">
            Update Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;