import React from "react";
import loading from "../../../assets/img/gif/loading.gif";

const Loading = () => {
  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        width: "100%",
      }}
    >
      <img
        style={{
          maxWidth: "150px",
          height: "auto",
          marginTop: "10%",
          marginBottom: "10%",
        }}
        src={loading}
        alt="spinner"
      />
    </section>
  );
};

export default Loading;
