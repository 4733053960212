import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHolidays } from "../../../../../store/slices/HolidaySlice";
import { holidaycalendar } from "../../../../../Routes/ImagePath";
import { Link } from "react-router-dom";

const Holiday = () => {
  const dispatch = useDispatch();
  const holidays = useSelector((state) => state.holidays.holidays);
  

  useEffect(() => {
    dispatch(fetchHolidays());
  }, [dispatch]);

  const upcomingHoliday = useMemo(() => {
    const currentDate = new Date();
    return holidays
      ?.filter((holiday) => new Date(holiday?.holidayDate) > currentDate)
      ?.sort(
        (a, b) => new Date(a?.holidayDate) - new Date(b?.holidayDate)
      )?.[0];
  }, [holidays]);

  return (
    <div className="col-12 card info-card flex-fill">
      <div className="card-body">
        <h4>Upcoming Holiday</h4>
        <div className="holiday-details">
          {upcomingHoliday ? (
            <div className="holiday-calendar">
              <div className="holiday-calendar-icon">
                <img src={holidaycalendar} alt="Icon" />
              </div>
              <div className="holiday-calendar-content">
                <h6>{upcomingHoliday?.title}</h6>
                <p>
                  {upcomingHoliday?.day?.slice(0, 3)},{" "}
                  {new Date(upcomingHoliday?.holidayDate).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                </p>
              </div>
            </div>
          ) : (
            <p className="text-white">No upcoming holidays</p>
          )}
          <div className="holiday-btn">
            <Link to="/holidays" className="btn">
              View All
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Holiday;
