import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Select, message } from "antd";
import { fetchUsersByDepartment, updateUser } from "../../../store/slices/UpworkSlice";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import { fetchDepartments } from "../../../store/slices/EmployeeSlice";

const Users = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth.userRole);
  const userUpworkRole = useSelector((state) => state?.user?.userDetails?.data?.user?.upworkRole);
  const { users, userStatus, error } = useSelector((state) => state.upwork);
  const userDepartment = useSelector((state) => state?.user?.userDetails?.data?.user?.department);
  const departments = useSelector((state) => state?.employee?.departments);
  const upworkDepartment = departments.find(v => v.type === 'upwork');
  const departmenId = userDepartment?.type === 'upwork' ? userDepartment?._id : upworkDepartment?._id;

  useEffect(() => {
    if (departmenId) return;
    dispatch(fetchDepartments());
  }, [dispatch, departmenId])

  useEffect(() => {
    if (departmenId) {
      dispatch(fetchUsersByDepartment(departmenId));
    }
  }, [dispatch, departmenId]);

  const handleUpworkRoleChange = async (id, upworkRole) => {
    try {
      await dispatch(updateUser({ id, updates: { upworkRole } })).unwrap();
      message.success("Upwork Role updated successfully!");
    } catch (err) {
      message.error(err || "Failed to update Upwork Role.");
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs maintitle="Users" title="Dashboard" subtitle="Users" />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns(handleUpworkRoleChange, userRole === 'admin' || userUpworkRole === 'manager')}
                  dataSource={prepareUsersForDisplay(users)}
                  className="table-striped"
                  rowKey={(record) => record._id}
                  locale={getTableLocale(userStatus, error)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;

// Helper to prepare users for display in the table
const prepareUsersForDisplay = (users) =>
  users.map((user, index) => ({
    key: index,
    id: index + 1,
    _id: user?._id,
    employeeId: user?.employeeId,
    fullName: `${user.firstName} ${user.lastName}`,
    email: user.email,
    role: user.role,
    upworkRole: user.upworkRole || "N/A",
    gender: user.gender || "N/A",
    designation: user.designation?.title || "N/A",
    dateOfJoining: user.dateOfJoining
      ? new Date(user.dateOfJoining).toLocaleDateString()
      : "N/A",
  }));

// Table columns configuration
const columns = (handleUpworkRoleChange, changeRole) => [
  { title: "#", dataIndex: "id", width: "5%" },
  { title: "Employee Id", dataIndex: "employeeId", width: "20%" },
  { title: "Full Name", dataIndex: "fullName", width: "20%" },
  { title: "Email", dataIndex: "email", width: "20%" },
  { title: "Role", dataIndex: "role", width: "15%" },
  {
    title: "Upwork Role",
    dataIndex: "upworkRole",
    width: "15%",
    render: (text, record) => {
      if (changeRole) {
        return <Select
          value={record.upworkRole}
          style={{ width: "100%" }}
          onChange={(value) => {
            handleUpworkRoleChange(record?._id, value);
          }}
        >
          <Select.Option value="manager">Manager</Select.Option>
          <Select.Option value="floor manager">Floor Manager</Select.Option>
          <Select.Option value="agent">Agent</Select.Option>
          <Select.Option value="bidder">Bidder</Select.Option>
        </Select>
      }
      return <div>
        {record.upworkRole}
      </div>
    }
  },
  { title: "Date of Joining", dataIndex: "dateOfJoining", width: "15%" },
];

// Utility to handle table state (loading, errors, empty)
const getTableLocale = (status, error) => ({
  emptyText:
    status === "loading" ? (
      <Loading />
    ) : status === "failed" ? (
      <div className="text-center text-danger">
        <Error404 />
      </div>
    ) : (
      "No Data"
    ),
});
