import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar_02 } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { fetchUserDetails } from "../../../store/slices/UserSlice";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";

const Profile = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const userId = useSelector((state) => state?.auth?.userInfo?.id);
  // Accessing user details state
  const { userDetails, status } = useSelector((state) => state?.user);

  useEffect(() => {
    if (isLoggedIn && userId) {
      dispatch(fetchUserDetails(userId));
    }
  }, [dispatch, isLoggedIn, userId]);

  if (status === "loading") {
    return (
      <div className="loading-effect">
        <Loading />
      </div>
    );
  }

  if (status === "failed") {
    return <Error404 />;
  }

  const {
    firstName,
    lastName,
    email,
    role,
    department,
    phone,
    gender,
    dateOfBirth,
    image,
    designation,
    employeeId,
  } = userDetails?.data?.user || {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Profile"
            title="Main"
            subtitle="Profile"
            modalClass="col-auto float-end ms-auto"
            modal="#add_indicator"
            name="Add New"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img src={image || Avatar_02} alt="" />
                        </Link>
                      </div>
                    </div>
                    {/* ----------------------------- */}
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {firstName} {lastName}
                            </h3>
                            <h6 className="text-muted">
                              Role: {role || "N/A"}
                            </h6>
                            <div className="staff-id">
                              Employee ID: {employeeId || "N/A"}
                            </div>
                            <div className="staff-id">
                              Designation: {designation?.title || "N/A"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">{phone || "N/A"}</div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">{email || "N/A"}</div>
                            </li>
                            <li>
                              <div className="title">Date of Birth:</div>
                              <div className="text">
                                {" "}
                                {dateOfBirth ? formatDate(dateOfBirth) : "N/A"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Gender:</div>
                              <div className="text">{gender || "N/A"}</div>
                            </li>
                            <li>
                              <div className="title">Department:</div>
                              <div className="text">
                                {department?.name || "N/A"}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------- */}
                    <div className="pro-edit">
                      <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_projects-change-password"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Change Password
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_projects-document"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Document
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ProfileTab userDetails={userDetails?.data?.user} />
        </div>
      </div>
    </>
  );
};

export default Profile;
