import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const AddTrackingModalPopup = ({
  onSubmit,
  onEdit,
  editTracking,
  mode,
  agents,
  projects,
}) => {
  const [agentId, setAgentId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [trackerStatus, setTrackerStatus] = useState("Off");
  const [appointments, setAppointments] = useState(1);
  const [workingHours, setHours] = useState(1);

  useEffect(() => {
    if (mode === "edit" && editTracking) {
      setAgentId(editTracking?.agent?._id || "");
      setProjectId(editTracking?.project?._id || "");
      setTrackerStatus(editTracking?.trackerStatus || "Off");
      setAppointments(editTracking?.appointments || 1);
      setHours(editTracking?.workingHours || 1);
    } else {
      resetForm();
    }
  }, [mode, editTracking]);

  const resetForm = () => {
    setAgentId("");
    setProjectId("");
    setTrackerStatus("Off");
    setAppointments(1);
    setHours(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      agent: agentId,
      project: projectId,
      trackerStatus,
      appointments,
      workingHours,
    };
    try {
      if (mode === "edit") {
        await onEdit({ id: editTracking?._id, trackingData: payload });
      } else {
        await onSubmit(payload);
      }
      resetForm();
      closeModal();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to save tracking record";
      toast.error(errorMessage);
    }
  };

  const closeModal = () => {
    const modalId = "edit";
    const modalElement = document.getElementById(modalId);
    const closeButton = modalElement?.querySelector(".btn-close");
    if (closeButton) closeButton.click();
  };

  return (
    <div id="edit" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{
          maxWidth: window.innerWidth < 1024 ? "100%" : "40%",
          margin: "0 auto",
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {mode === "edit" ? "Edit Tracking" : "Add Tracking"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Agent <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={agentId}
                  onChange={(e) => setAgentId(e.target.value)}
                >
                  <option value="">Select Agent</option>
                  {agents?.map((agent) => (
                    <option key={agent._id} value={agent._id}>
                      {agent.firstName} {agent.lastName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Project <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={projectId}
                  onChange={(e) => setProjectId(e.target.value)}
                >
                  <option value="">Select Project</option>
                  {projects?.map((project) => (
                    <option key={project._id} value={project._id}>
                      {project.clientName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Tracker Status <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={trackerStatus}
                  onChange={(e) => setTrackerStatus(e.target.value)}
                >
                  <option value="Off">Off</option>
                  <option value="On">On</option>
                  <option value="Started">Started</option>
                  <option value="Completed">Completed</option>
                  <option value="No Tracker">No Tracker</option>
                  <option value="Idle Agent">Idle Agent</option>
                </select>
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Appointments <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={appointments}
                  onChange={(e) => setAppointments(Number(e.target.value))}
                >
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Working Hours <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={workingHours}
                  onChange={(e) => setHours(Number(e.target.value))}
                >
                  {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </div>

              <div className="submit-section">
                <button className="btn btn-primary submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTrackingModalPopup;
