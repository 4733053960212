import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import SearchBox from "../../../components/SearchBox";
import {
  fetchAllTrackings,
  fetchAllProjects,
  fetchAllAgents,
  createTracking,
  updateTracking,
  deleteTracking,
} from "../../../store/slices/UpworkSlice";
import { toast } from "react-toastify";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import ActionMenu from "./ActionsMenu";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AddTrackingModalPopup from "../../../components/modelpopup/AddTrackingModal";
import DeleteModal from "../../../components/modelpopup/DeleteModal";

const Trackings = () => {
  const [selectedTracking, setSelectedTracking] = useState(null);
  const [mode, setMode] = useState(null);
  const [filteredTrackings, setFilteredTrackings] = useState([]);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.userRole);
  const upworkRole = useSelector((state) => state?.user?.userDetails?.data?.user?.upworkRole);
  const { trackings, agents, projects, status, error } = useSelector(
    (state) => state?.upwork || {}
  );

  useEffect(() => {
    dispatch(fetchAllTrackings());
    dispatch(fetchAllProjects());
    dispatch(fetchAllAgents());
  }, [dispatch]);

  useEffect(() => {
    setFilteredTrackings(trackings);
  }, [trackings]);

  // Fetch the latest tracking data after add or edit
  const refetchData = async () => {
    try {
      await dispatch(fetchAllTrackings());
    } catch (error) {
      console.error("Failed to refetch trackings", error);
    }
  };

  // Handle adding a tracking record
  const handleAddTracking = async (trackingData) => {
    try {
      await dispatch(createTracking(trackingData)).unwrap();
      await refetchData(); // Fetch updated data
      toast.success("Tracking record added successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add tracking record");
    }
  };

  // Handle updating a tracking record
  const handleUpdateTracking = async ({ id, trackingData }) => {
    try {
      await dispatch(updateTracking({ id, trackingData })).unwrap();
      await refetchData(); // Fetch updated data
      toast.success("Tracking record updated successfully");
      resetSelection();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update tracking record");
    }
  };

  // Handle deleting a tracking record
  const handleDelete = async () => {
    if (selectedTracking) {
      try {
        await dispatch(deleteTracking(selectedTracking._id)).unwrap();
        await refetchData(); // Fetch updated data
        toast.success("Tracking record deleted successfully");
        resetSelection();
      } catch (error) {
        toast.error(error.response?.data?.message || "Failed to delete tracking record");
      }
    }
  };

  // Handle editing a tracking record
  const handleEditTracking = (tracking) => {
    setSelectedTracking(tracking);
    setMode("edit");
  };

  // Handle deleting a tracking record
  const handleDeleteTracking = (tracking) => {
    setSelectedTracking(tracking);
    document.getElementById("delete").click();
  };

  const resetSelection = () => {
    setSelectedTracking(null);
    setMode(null);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Trackings"
            title="Dashboard"
            subtitle="Trackings"
            modal="#edit"
            name="Add Tracking"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns(handleEditTracking, handleDeleteTracking, handleUpdateTracking, role, upworkRole)}
                  dataSource={prepareTrackingsForDisplay(filteredTrackings)}
                  className="table-striped"
                  rowKey={(record) => record._id}
                  locale={getTableLocale(status, error)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTrackingModalPopup
        onSubmit={handleAddTracking}
        onEdit={handleUpdateTracking}
        editTracking={selectedTracking}
        mode={mode}
        agents={agents}
        projects={projects}
      />
      <DeleteModal Name="Delete Tracking" onDeleteLeave={handleDelete} />
    </div>
  );
};

export default Trackings;

const prepareTrackingsForDisplay = (trackings) => {
  return trackings?.map((tracking, index) => ({
    key: index,
    id: index + 1,
    ...tracking,
    date: new Date(tracking.date).toLocaleDateString(),
    agentName: `${tracking.agent?.firstName || ""} ${tracking.agent?.lastName || ""}`,
    projectName: tracking.project?.clientName || "",
    trackerStatus: tracking.trackerStatus,
    appointments: tracking.appointments,
    isApproved: tracking.isApproved ? "Yes" : "No",
  }));
};

const columns = (handleEditTracking, handleDeleteTracking, handleUpdateTracking, role, upworkRole) => [
  { title: "#", dataIndex: "id", width: "5%" },
  { title: "Date", dataIndex: "date", width: "15%" },
  { title: "Agent", dataIndex: "agentName", width: "15%" },
  { title: "Project", dataIndex: "projectName", width: "15%" },
  { title: "Tracker Status", dataIndex: "trackerStatus", width: "10%" },
  { title: "Appointments", dataIndex: "appointments", width: "10%" },
  { title: "Working Hours", dataIndex: "workingHours", width: "10%" },
  {
    title: "Approved",
    dataIndex: "isApproved",
    width: "10%",
    render: (text, record) => (
      role === "admin" || upworkRole !== "agent" ?
        (<div className="dropdown action-label text-start">
          <span
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className={`far fa-dot-circle ${text === "Yes" ? 'text-success' : 'text-danger'}`}
            /> {text}
          </span>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              onClick={() => {
                handleUpdateTracking({ id: record._id, trackingData: { isApproved: true } })
              }}
            >
              <i className="far fa-dot-circle text-success" /> Yes
            </span>
            <span
              className="dropdown-item"
              onClick={() => {
                handleUpdateTracking({ id: record._id, trackingData: { isApproved: false } })
              }}
            >
              <i className="far fa-dot-circle text-danger" /> No
            </span>
          </div>
        </div>) :
        (<div>{text}</div>)
    )
  },
  {
    ...(upworkRole !== 'agent' && {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <ActionMenu
          record={record}
          onEdit={handleEditTracking}
          onDelete={handleDeleteTracking}
        />
      ),
      width: "15%",
    }),
  }
];

const getTableLocale = (status, error) => {
  return {
    emptyText: status?.fetchAllTrackings === "loading" ? (
      <Loading />
    ) : error?.fetchAllTrackings ? (
      <div className="text-center text-danger">
        <Error404 />
      </div>
    ) : (
      "No Data"
    ),
  };
};
