import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = `${process.env.REACT_APP_BASE_URL}`;

// Async thunk to fetch holidays
export const fetchHolidays = createAsyncThunk(
  "holidays/fetchHolidays",
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.userToken; // Get the auth token from the state
      const response = await axios.get(`${base_url}/api/holidays`, {
        headers: { Authorization: `Bearer ${token}` }, // Add the token to the headers
      });
      
      return response.data.data.holidays;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.data?.message || "Failed to fetch holidays"
      );
    }
  }
);

// Async thunk to add a holiday
export const addHoliday = createAsyncThunk(
  "holidays/addHoliday",
  async (holidayData, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.userToken; 
      const response = await axios.post(`${base_url}/api/holidays`, holidayData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data.holiday;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to add holiday"
      );
    }
  }
);

// Async thunk to update a holiday
export const updateHoliday = createAsyncThunk(
  "holidays/updateHoliday",
  async ({ id, data }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.userToken; // Get the auth token from the state
      const response = await axios.patch(`${base_url}/api/holidays/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` }, // Add the token to the headers
      });

      return response.data.data.holiday;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message  || "Failed to update holiday"
      );
    }
  }
);

// Async thunk to remove a holiday
export const removeHoliday = createAsyncThunk(
  "holidays/removeHoliday",
  async (id, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.userToken; // Get the auth token from the state
      await axios.delete(`${base_url}/api/holidays/${id}`, {
        headers: { Authorization: `Bearer ${token}` }, // Add the token to the headers
      });
      return id;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message  || "Failed to delete holiday"
      );
    }
  }
);

// Holiday slice
const holidaySlice = createSlice({
  name: "holidays",
  initialState: {
    holidays: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolidays.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchHolidays.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.holidays = action.payload;
      })
      .addCase(fetchHolidays.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addHoliday.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addHoliday.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.holidays.push(action.payload);
      })
      .addCase(addHoliday.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateHoliday.fulfilled, (state, action) => {
        const index = state.holidays.findIndex(
          (holiday) => holiday._id === action.payload._id
        );
        if (index !== -1) {
          state.holidays[index] = action.payload;
        }
      })
      .addCase(removeHoliday.fulfilled, (state, action) => {
        state.holidays = state.holidays.filter(
          (holiday) => holiday._id !== action.payload
        );
      });
  },
});

export default holidaySlice.reducer;
