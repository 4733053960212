import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Loading from "../../../hooks/utils/Loading/Loading";
import {
  fetchEmployees,
  updateEmployeeStatus,
} from "../../../store/slices/EmployeeSlice";
import { User } from "../../../Routes/ImagePath";
import { Pagination } from "antd";

const AllEmployee = () => {
  const dispatch = useDispatch();
  const { employees, status } = useSelector((state) => state?.employee);
  const total = useSelector((state) => state?.employee?.total);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({})

  useEffect(() => {
    dispatch(fetchEmployees({ pagination: { page: currentPage, limit: pageSize } , filter: filter }));
  }, [dispatch, currentPage, pageSize, filter]);

  useEffect(() => {
    setFilteredEmployees(employees);
  }, [employees]);
  
  const handleSearch = ({ employeeId, employeeName, department }) => {
    const filter = {
      ...(employeeId && { employeeId }),
      ...(employeeName && { firstName: employeeName, }),
      ...(department?.value && { department: department.value })
    };
    setCurrentPage(1);
    setFilter(filter);
  };

  const handleStatusChange = (employee, status) => {
    dispatch(updateEmployeeStatus({ _id: employee._id, status }));
  };

  const handlePageChange = (page, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  const renderActionButton = (employee) => {
    if (employee.status === "active") {
      return (
        <div className="d-flex flex-column">
          <Link
            className="dropdown-item"
            onClick={() => handleStatusChange(employee, "disabled")}
          >
            <i className="fa fa-ban m-r-5" /> Disable
          </Link>
        </div>
      );
    } else if (employee.status === "disabled") {
      return (
        <>
          <Link
            className="dropdown-item"
            onClick={() => handleStatusChange(employee, "active")}
          >
            <i className="fa fa-toggle-on m-r-5" />
            Activate
          </Link>
        </>
      );
    }
  };

  const renderEmployeeCard = (employee) => {
    const isDisabled = employee.status === "disabled";
    return (
      <div
        className={`col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3 ${isDisabled ? "disabled-card" : ""
          }`}
        key={employee._id}
        style={{
          opacity: isDisabled ? 0.6 : 1,
          borderColor: isDisabled ? "#dc3545" : "#ccc",
        }}
      >
        <div className="profile-widget">
          <div
            className="card-label"
            style={{
              width: "fit-content",
              marginTop: "-10px",
              marginLeft: "-20px",
              padding: "3px 18px 1px 10px",
              backgroundColor: "#4caf50",
              color: "white",
              fontSize: "12px",
              borderRadius: "0 15px 15px 0",
              border: "1px solid #4caf50",
              clipPath: "polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0 100%)",
              whiteSpace: "nowrap",
            }}
          >
            {employee?.department?.name || "N/A"}
          </div>
          <div className="profile-img">
            <Link className="avatar" to={`/employeeprofile/${employee._id}`}>
              {employee.image ? (
                <img
                  src={employee.image}
                  alt={employee.firstName + employee.lastName}
                />
              ) : (
                <img src={User} alt={employee.firstName + employee.lastName} />
              )}
            </Link>
          </div>
          <div className="dropdown profile-action">
            <Link
              to={`/employeeprofile/${employee._id}`}
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">profile</i>
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              {renderActionButton(employee)}
            </div>
          </div>
          <h4 className="user-name m-t-10 mb-0 text-ellipsis">
            <Link to={`/employeeprofile/${employee._id}`}>
              {employee.firstName + " " + employee.lastName}
            </Link>
          </h4>
          <div className="small text-muted">{employee.role}</div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (status === "loading") {
      return (
        <div className="text-center">
          <Loading />
        </div>
      );
    }

    if (filteredEmployees?.length === 0) {
      return <div className="text-center">No employees found</div>;
    }

    return filteredEmployees?.map((employee) => renderEmployeeCard(employee));
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
            modalClass="col-auto float-end ms-auto"
          />
          <EmployeeListFilter onSearch={handleSearch} />

          <div className="row">{renderContent()}</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={total}
              onChange={handlePageChange}
              showSizeChanger
              pageSizeOptions={["10", "20", "50", "100"]}
              showQuickJumper
              showTotal={(total) => `Total ${total} employees`}
              itemRender={(page, type, originalElement) => {
                if (type === "prev") {
                  return <span>Previous</span>;
                }
                if (type === "next") {
                  return <span>Next</span>;
                }
                if (type === "jump-prev" || type === "jump-next") {
                  return <span>...</span>;
                }
                return originalElement;
              }}
            />
          </div>
        </div>
      </div>

      <AllEmployeeAddPopup />
    </div>
  );
};

export default AllEmployee;
