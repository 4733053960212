import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddBidModalPopup = ({ onSubmit, onEdit, editBid, mode }) => {
  const [upworkId, setUpworkId] = useState("");
  const [jobPostingLink, setJobPostingLink] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [connectNumber, setConnectNumber] = useState("");
  const [response, setResponse] = useState(false); // Boolean for client response

  // Get the logged-in user's ID from the auth slice
  const loggedInUserId = useSelector((state) => state?.auth?.userInfo?.id);

  useEffect(() => {
    if (mode === "edit" && editBid) {
      setUpworkId(editBid?.upworkId);
      setJobPostingLink(editBid?.jobPostingLink);
      setJobTitle(editBid?.jobTitle);
      setConnectNumber(editBid?.connectNumber);
      setResponse(editBid?.response);
    } else {
      resetForm();
    }
  }, [mode, editBid]);

  const resetForm = () => {
    setUpworkId("");
    setJobPostingLink("");
    setJobTitle("");
    setConnectNumber("");
    setResponse(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      upworkId,
      jobPostingLink,
      jobTitle,
      connectNumber,
      response,
      bidder: loggedInUserId, // Automatically set the bidder as the logged-in user
    };

    try {
      if (mode === "edit") {
        await onEdit({ id: editBid?._id, bidData: payload });
      } else {
        await onSubmit(payload);
      }
      resetForm();
      closeModal();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to save bid";
      toast.error(errorMessage);
    }
  };

  const closeModal = () => {
    const modalId = "edit";
    const modalElement = document.getElementById(modalId);
    const closeButton = modalElement?.querySelector(".btn-close");
    if (closeButton) closeButton.click();
  };

  return (
    <div id="edit" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{
          maxWidth: window.innerWidth < 1024 ? "100%" : "40%",
          margin: "0 auto",
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {mode === "edit" ? "Edit Bid" : "Add Bid"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Upwork ID <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={upworkId}
                  onChange={(e) => setUpworkId(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Job Posting Link <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="url"
                  required
                  value={jobPostingLink}
                  onChange={(e) => setJobPostingLink(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Job Title <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Connect Number <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  required
                  value={connectNumber}
                  onChange={(e) => setConnectNumber(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">Client Response</label>
                <select
                  className="form-control"
                  value={response}
                  onChange={(e) => setResponse(e.target.value === "true")}
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>

              <div className="submit-section">
                <button className="btn btn-primary submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBidModalPopup;