import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Documents = ({ userDetails }) => {
  // const [editModalName, setEditModalName] = useState(null);

  const profileInfo = useSelector(
    (state) => state?.user?.userDetails?.data?.user
  );

  const renderDocumentPreview = (url, fileType) => {
    if (!url) return "No Document Uploaded";

    if (fileType === "application/pdf") {
      return (
        <iframe
          src={url + "#toolbar=0"}
          title="Document PDF"
          style={{ width: "100%", height: "300px" }}
        />
      );
    } else {
      return (
        <img
          src={url}
          alt="Document Preview"
          style={{ width: "100%", height: "auto" }}
        />
      );
    }
  };

  const documents = profileInfo?.documents?.[0];

  return (
    <div className="container">
      <div className="row">
        {/* Resume Card */}
        <div className="col-md-6 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title">
                RESUME
                <Link
                  to="#"
                  className="edit-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#resume"
                  // onClick={() => setEditModalName("resume")}
                >
                  <i className="fa fa-pencil" />
                </Link>
              </h3>
              <div className="experience-box">
                <div>
                  <div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        {renderDocumentPreview(
                          documents?.resume?.url,
                          "application/pdf"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CNIC Card */}
        <div className="col-md-6 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title">
                CNIC
                <Link
                  to="#"
                  className="edit-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#cnic"
                  // onClick={() => setEditModalName("cnic")}
                >
                  <i className="fa fa-pencil" />
                </Link>
              </h3>
              <div className="experience-box">
                <div>
                  <div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        {renderDocumentPreview(
                          documents?.cnic?.url,
                          "application/pdf"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Experience Letter Card */}
        <div className="col-md-6 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title">
                EXPERIENCE LETTER
                <Link
                  to="#"
                  className="edit-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#expLetter"
                  // onClick={() => setEditModalName("expLetter")}
                >
                  <i className="fa fa-pencil" />
                </Link>
              </h3>
              <div className="experience-box">
                <div>
                  <div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        {renderDocumentPreview(
                          documents?.expLetter?.url,
                          "application/pdf"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Degree Card */}
        <div className="col-md-6 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title">
                DEGREE
                <Link
                  to="#"
                  className="edit-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#degree"
                  // onClick={() => setEditModalName("degree")}
                >
                  <i className="fa fa-pencil" />
                </Link>
              </h3>
              <div className="experience-box">
                <div>
                  <div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        {renderDocumentPreview(
                          documents?.degree?.url,
                          "application/pdf"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
