import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, requiredRole }) => {
    const user = useSelector((state) => state.auth.isLoggedIn);
    const userRole = useSelector((state) => state.auth.userRole);
    let location = useLocation();

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (requiredRole && !requiredRole?.includes(userRole)) {
        // If the user does not have the required role, redirect to home or a forbidden page
        return <Navigate to="/error" replace />;
    }

    return children;
};

export default ProtectedRoute;
