import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import useFetch from "../../../../../hooks/api/fetchHook";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import CallsTable from "./CallsTable.jsx";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import { fetchAllEmployees } from "../../../../../store/slices/EmployeeSlice.js";

const Calls = () => {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state?.employee?.allEmployees);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [salesAssociate, setSalessAssociate] = useState("");
  const [filter, setFilter] = useState({});

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleDateChangeTwo = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    dispatch(fetchAllEmployees({}));
  }, [dispatch]);

  const usersOptions = employees
    .filter((user) => user?.department?.type === "sales")
    .map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName}`,
    }));

  const typeOptions = [
    {
      value: "Transaction",
      label: "Transaction",
    },
    {
      value: "Dead Air",
      label: "Dead Air",
    },
    {
      value: "Exempted",
      label: "Exempted",
    },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "50px",
      borderRadius: "4px",
      borderColor: state.isFocused ? "#ff9b44" : provided.borderColor,
      paddingTop: "10px",
      "&:hover": {
        borderColor: "#ff9b44",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleSearch = () => {
    const filter = {
      ...(startDate ? { startDate: new Date(startDate) } : {}),
      ...(endDate ? { endDate: new Date(endDate) } : {}),
      ...(salesAssociate ? { user: salesAssociate } : {}),
      ...(selectedType ? { type: selectedType } : {}),
    };

    setFilter(filter);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Calls"
            title="Main"
            subtitle="Calls"
            modal="#"
            name="Create Call"
            link="/create-call"
            modalClass="col-auto float-end ms-auto"
          />

          <div className="row filter-row">
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${
                  startDate ? "focused" : ""
                }`}
              >
                <div className="cal-icon focused">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={startDate}
                    onChange={handleDateChange}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">From</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${
                  endDate ? "focused" : ""
                }`}
              >
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={endDate}
                    onChange={handleDateChangeTwo}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">To</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Call Type"
                  onChange={(type) => {
                    setSelectedType(type.value);
                  }}
                  options={typeOptions}
                  styles={customStyles}
                />
                <label className="focus-label">Call Type</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Sales Associates"
                  onChange={(user) => setSalessAssociate(user.value)}
                  // defaultValue={users[0]}
                  options={usersOptions}
                  styles={customStyles}
                />
                <label className="focus-label">Sales Associate</label>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <Link
                className="btn btn-success btn-block w-100"
                onClick={handleSearch}
              >
                Search
              </Link>
            </div>
          </div>

          <CallsTable filter={filter} />
        </div>
      </div>
      <DeleteModal Name="Delete Estimate" />
    </>
  );
};

export default Calls;
