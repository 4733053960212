import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { User } from "../../../../../Routes/ImagePath";
import { useSelector, useDispatch } from "react-redux";
import useFetch from "../../../../../hooks/api/fetchHook";
import { fetchAllLeaves } from "../../../../../store/slices/LeaveSlice";
import { fetchDailyReport } from "../../../../../store/slices/CallSlice";
import { fetchEmployees } from "../../../../../store/slices/EmployeeSlice";

const Statistics = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const total = useSelector((state) => state?.employee?.total);
  const { dailyReport: saleData } = useSelector((state) => state?.calls || {});

  const { userToken, leaves, attendance } = useSelector((state) => ({
    userToken: state?.auth?.userToken,
    leaves: state?.leave?.leaves,
    attendance: state?.attendance?.attendance,
  }));

  useEffect(() => {
    dispatch(fetchAllLeaves());
    dispatch(fetchDailyReport({ startDate, endDate }));
    dispatch(
      fetchEmployees({ pagination: { page: currentPage, limit: pageSize } })
    );
  }, [dispatch, startDate, endDate, currentPage, pageSize]);

  const reportData = saleData || [];

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData, loading, error } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/attendance`,
    options
  );

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const { totalEmployees, todayAttendanceCount } = useMemo(() => {
    if (!apiData?.data?.attendance) {
      return { totalEmployees: 0, todayAttendanceCount: 0 };
    }

    const totalEmployees = apiData.data.attendance.length;
    const today = getTodayDate();

    const todayAttendanceCount = apiData.data.attendance.reduce(
      (count, admin) => {
        const todayPunches = admin.punches.filter((punch) =>
          punch.time.startsWith(today)
        );
        return count + (todayPunches.length > 0 ? 1 : 0);
      },
      0
    );

    return { totalEmployees, todayAttendanceCount };
  }, [apiData]);

  const attendancePercentage =
    totalEmployees > 0 ? ((todayAttendanceCount / total) * 100).toFixed(2) : 0;

  const { activeAdmins, onBreakAdmins } = useMemo(() => {
    const active = [];
    const onBreak = [];
    const today = getTodayDate();

    apiData?.data?.attendance?.forEach((admin) => {
      const todayPunches = admin.punches.filter((punch) =>
        punch.time.startsWith(today)
      );

      if (todayPunches.length > 0) {
        const lastPunch = todayPunches[todayPunches.length - 1];

        if (lastPunch?.type === "in") {
          active.push(admin.user);
        } else if (lastPunch?.type === "out") {
          onBreak.push(admin.user);
        }
      }
    });

    return {
      activeAdmins: active,
      onBreakAdmins: onBreak,
    };
  }, [apiData]);

  const todayLeaves = useMemo(() => {
    const today = getTodayDate();

    return leaves?.filter((leave) => {
      const leaveStartDate = new Date(leave.startDate)
        .toISOString()
        .split("T")[0];

      const hasPunchedInToday = apiData?.data?.attendance?.some(
        (att) =>
          att.user &&
          leave.userId &&
          att.user._id === leave.userId._id &&
          att.punches.some(
            (punch) => punch.time.startsWith(today) && punch.type === "in"
          )
      );

      return (
        leaveStartDate === today &&
        leave.status === "Accepted" &&
        !hasPunchedInToday
      );
    });
  }, [leaves, apiData]);

  const todayAbsent = useMemo(() => {
    const today = getTodayDate();

    return leaves?.filter((leave) => {
      const leaveStartDate = new Date(leave.startDate)
        .toISOString()
        .split("T")[0];

      const hasPunchedInToday = apiData?.data?.attendance?.some(
        (att) =>
          att.user &&
          leave.userId &&
          att.user._id === leave.userId._id &&
          att.punches.some(
            (punch) => punch.time.startsWith(today) && punch.type === "in"
          )
      );

      return (
        leaveStartDate === today &&
        (leave.status === "Pending" || leave.status === "Accepted") &&
        !hasPunchedInToday
      );
    });
  }, [leaves, apiData]);

  return (
    <div>
      <div className="row">
        <div className="col-12 col-lg-6 d-flex">
          <div className="card flex-fill dash-statistics">
            <div className="card-body">
              <h5 className="card-title">Statistics</h5>
              <div className="stats-list">
                {/* <div className="stats-info">
                  <p>
                    Today Leave{" "}
                    <strong>
                      {todayLeaves.length}{" "}
                      <small>/ {leaves?.length || 0}</small>
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      style={{
                        width: `${
                          (todayLeaves.length / (leaves?.length || 1)) * 100
                        }%`,
                      }}
                      aria-valuenow={
                        (todayLeaves.length / (leaves?.length || 1)) * 100
                      }
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div> */}
                <div className="stats-info">
                  <p>
                    Today Attendance{" "}
                    <strong>
                      {todayAttendanceCount} <small>/ {total}</small>
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: `${attendancePercentage}%` }}
                      aria-valuenow={attendancePercentage}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="stats-info">
                  <p className="mb-0">
                    Today's Total Sales{" "}
                    <strong>{reportData[0]?.transactionCalls}</strong>
                  </p>
                </div>
                <div className="stats-info">
                  <p className="mb-0">
                    Today's Total Balance{" "}
                    <strong>
                      <strong>
                        ${reportData[0]?.acceptedTransactionTotal}
                      </strong>
                    </strong>
                  </p>
                </div>
                {/* <div className="stats-info">
                  <p>
                    Closed Tickets{" "}
                    <strong>
                      22 <small>/ 212</small>
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{ width: "22%" }}
                      aria-valuenow={22}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">Task Statistics</h4>
              <div className="statistics">
                <div className="row">
                  <div className="col-md-6 col-6 text-center">
                    <div className="stats-box mb-4">
                      <p>Total Tasks</p>
                      <h3>385</h3>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 text-center">
                    <div className="stats-box mb-4">
                      <p>Overdue Tasks</p>
                      <h3>19</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="progress mb-4">
                <div
                  className="progress-bar bg-purple"
                  role="progressbar"
                  style={{ width: "30%" }}
                  aria-valuenow={30}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  30%
                </div>
                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={{ width: "22%" }}
                  aria-valuenow={18}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  22%
                </div>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: "24%" }}
                  aria-valuenow={12}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  24%
                </div>
                <div
                  className="progress-bar bg-danger"
                  role="progressbar"
                  style={{ width: "26%" }}
                  aria-valuenow={14}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  21%
                </div>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "10%" }}
                  aria-valuenow={14}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  10%
                </div>
              </div>
              <div>
                <p>
                  <i className="far fa-dot-circle text-purple me-2" />
                  Completed Tasks <span className="float-end">166</span>
                </p>
                <p>
                  <i className="far fa-dot-circle text-warning me-2" />
                  Inprogress Tasks <span className="float-end">115</span>
                </p>
                <p>
                  <i className="far fa-dot-circle text-success me-2" />
                  On Hold Tasks <span className="float-end">31</span>
                </p>
                <p>
                  <i className="far fa-dot-circle text-danger me-2" />
                  Pending Tasks <span className="float-end">47</span>
                </p>
                <p className="mb-0">
                  <i className="far fa-dot-circle text-info me-2" />
                  Review Tasks <span className="float-end">5</span>
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">
                Today Absent{" "}
                <span className="badge bg-inverse-danger ms-2">5</span>
              </h4>
              <div className="leave-info-box">
                <div className="media d-flex align-items-center">
                  <Link to="/profile" className="avatar">
                    <img alt="" src={User} />
                  </Link>
                  <div className="media-body">
                    <div className="text-sm my-0">Martin Lewis</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">4 Sep 2023</h6>
                    <span className="text-sm text-muted">Leave Date</span>
                  </div>
                  <div className="col-6 text-end">
                    <span className="badge bg-inverse-danger">Pending</span>
                  </div>
                </div>
              </div>
              <div className="leave-info-box">
                <div className="media d-flex align-items-center">
                  <Link to="/profile" className="avatar">
                    <img alt="" src={User} />
                  </Link>
                  <div className="media-body">
                    <div className="text-sm my-0">Martin Lewis</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">4 Sep 2023</h6>
                    <span className="text-sm text-muted">Leave Date</span>
                  </div>
                  <div className="col-6 text-end">
                    <span className="badge bg-inverse-success">Approved</span>
                  </div>
                </div>
              </div>
              <div className="load-more text-center">
                <Link className="text-dark" to="#">
                  Load More
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-12 col-lg-6 d-flex">
          <div
            className="card flex-fill"
            style={{ maxHeight: "420px", overflowY: "auto" }}
          >
            <div className="card-body">
              <h4 className="card-title">
                Today Absent{" "}
                <span className="badge bg-inverse-danger ms-2">
                  {todayAbsent.length}
                </span>
              </h4>
              {todayAbsent.map((leave, index) => (
                <div className="leave-info-box" key={index}>
                  <div className="media d-flex align-items-center">
                    <Link to="/profile" className="avatar">
                      <img alt="" src={User} />
                    </Link>
                    <div className="media-body">
                      <div className="text-sm my-0">{leave.userId.name}</div>
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-6">
                      <h6 className="mb-0">
                        {new Date(leave.startDate).toLocaleDateString()}
                      </h6>
                      <span className="text-sm text-muted">Leave Date</span>
                    </div>
                    <div className="col-6 text-end">
                      <span
                        className={`badge bg-inverse-${
                          leave.status === "Pending" ? "danger" : "success"
                        }`}
                      >
                        {leave.status}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">Working Status</h4>
              <div className="statistics d-flex justify-content-center">
                <div className="row col-11 d-flex justify-content-between">
                  {/* Active Admins */}
                  <div className="col-12 col-lg-5 text-center stats-box">
                    <h3 className="mt-2">Active ({activeAdmins.length})</h3>
                    <div
                      className="my-2"
                      style={{ height: "164px", overflowY: "auto" }}
                    >
                      {activeAdmins.length > 0 ? (
                        activeAdmins.map((admin) => (
                          <div key={admin._id} className="mt-1">
                            <p style={{ fontSize: "14px" }}>
                              {admin.firstName} {admin.lastName}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p className="mt-1" style={{ fontSize: "14px" }}>
                          No active admins
                        </p>
                      )}
                    </div>
                  </div>

                  {/* On Break Admins */}
                  <div className="col-12 col-lg-5 text-center stats-box">
                    <h3 className="mt-2">On Break ({onBreakAdmins.length})</h3>
                    <div
                      className="my-2"
                      style={{ height: "164px", overflowY: "auto" }}
                    >
                      {onBreakAdmins.length > 0 ? (
                        onBreakAdmins.map((admin) => (
                          <div key={admin._id} className="mt-1">
                            <p style={{ fontSize: "14px" }}>
                              {admin.firstName} {admin.lastName}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p className="mt-1" style={{ fontSize: "14px" }}>
                          No admins on break
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
