import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { fetchBidderStatistics } from "../../../store/slices/UpworkSlice";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import Breadcrumbs from "../../../components/Breadcrumbs";

const BidderStatistics = () => {
  const dispatch = useDispatch();
  const { bidderStatistics, status, error } = useSelector(
    (state) => state?.upwork || {}
  );
  const [filter, setFilter] = useState({
    statFrom: '',
    statTo: '',
  });

  function handleFilter(key, value) {
    setFilter(prev => {
      return {
        ...prev,
        [key]: value
      }
    });
  }


  useEffect(() => {
    const date = {
      ...(filter.statFrom && { gte: filter.statFrom }),
      ...(filter.statTo && { lte: filter.statTo }),
    };
    dispatch(fetchBidderStatistics({ filter: { date: date } }));
  }, [dispatch, filter]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Bidder Statistics"
            title="Dashboard"
            subtitle="Statistics"
          />
          <div className="row">
            <div className="col-md-3">
              <div className="input-block mb-3">
                <label className="col-form-label">Start Date</label>
                <input
                  className="form-control floating datetimepicker"
                  type="date"
                  value={
                    filter?.statFrom
                      ? new Date(filter?.statFrom)
                        .toISOString()
                        .split("T")[0]
                      : ""
                  }
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => handleFilter('statFrom', e.target.value)}
                  placeholder="Start Date"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="input-block mb-3">
                <label className="col-form-label">End Date</label>
                <input
                  className="form-control floating datetimepicker"
                  type="date"
                  value={
                    filter?.statTo
                      ? new Date(filter?.statTo)
                        .toISOString()
                        .split("T")[0]
                      : ""
                  }
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => handleFilter('statTo', e.target.value)}
                  placeholder="End date"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={prepareStatisticsForDisplay(bidderStatistics)}
                  className="table-striped"
                  rowKey={(record) => record.bidderName}
                  locale={getTableLocale(status, error)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidderStatistics;

// Helper to format statistics for display
const prepareStatisticsForDisplay = (statistics) => {
  return statistics.map((stat, index) => ({
    key: index,
    id: index + 1,
    ...stat,
  }));
};

// Table columns
const columns = [
  { title: "#", dataIndex: "key", width: "5%" },
  { title: "Bidder Name", dataIndex: "bidderName", width: "20%" },
  { title: "Total Projects", dataIndex: "projects", width: "15%" },
  { title: "Ongoing Projects", dataIndex: "ongoingProjects", width: "15%" },
  { title: "Completed Projects", dataIndex: "completedProjects", width: "15%" },
  { title: "Total Earnings", dataIndex: "totalEarnings", width: "15%" },
  { title: "Ongoing Earnings", dataIndex: "ongoingEarnings", width: "15%" },
];

// Utility for table locale (loading, errors, empty)
const getTableLocale = (status, error) => {
  return {
    emptyText: status?.fetchBidderStatistics === "loading" ? (
      <Loading />
    ) : error?.fetchBidderStatistics ? (
      <div className="text-center text-danger">
        <Error404 />
      </div>
    ) : (
      "No Data"
    ),
  };
};
