// src/slices/assetSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = `${process.env.REACT_APP_BASE_URL}`;

// Fetch all assets
export const fetchAllAssets = createAsyncThunk(
  "asset/fetchAllAssets",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(`${base_url}/api/assets`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response?.data?.data?.assets;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch assets by user ID
export const fetchAssetsByUserId = createAsyncThunk(
  "asset/fetchAssetsByUserId",
  async (userId, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(
        `${base_url}/api/assets/user/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data.assets;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Create a new asset
export const createAsset = createAsyncThunk(
  "asset/createAsset",
  async (assetData, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.post(`${base_url}/api/assets`, assetData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data.asset;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Update an asset
export const updateAsset = createAsyncThunk(
  "asset/updateAsset",
  async ({ id, assetData }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.patch(
        `${base_url}/api/assets/${id}`,
        assetData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data.asset;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// Delete an asset
export const deleteAsset = createAsyncThunk(
  "asset/deleteAsset",
  async (id, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      await axios.delete(`${base_url}/api/assets/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const assetSlice = createSlice({
  name: "asset",
  initialState: {
    assets: [],
    status: {
      fetchAllAssets: "idle",
      fetchByUserId: "idle",
      createAsset: "idle",
      updateAsset: "idle",
      deleteAsset: "idle",
    },
    error: {
      fetchAllAssets: null,
      fetchByUserId: null,
      createAsset: null,
      updateAsset: null,
      deleteAsset: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all assets
      .addCase(fetchAllAssets.pending, (state) => {
        state.status.fetchAllAssets = "loading";
      })
      .addCase(fetchAllAssets.fulfilled, (state, action) => {
        state.status.fetchAllAssets = "succeeded";
        state.assets = action.payload;
      })
      .addCase(fetchAllAssets.rejected, (state, action) => {
        state.status.fetchAllAssets = "failed";
        state.error.fetchAllAssets = action.payload;
      })

      // Fetch assets by user ID
      .addCase(fetchAssetsByUserId.pending, (state) => {
        state.status.fetchByUserId = "loading";
      })
      .addCase(fetchAssetsByUserId.fulfilled, (state, action) => {
        state.status.fetchByUserId = "succeeded";
        state.assets = action.payload;
      })
      .addCase(fetchAssetsByUserId.rejected, (state, action) => {
        state.status.fetchByUserId = "failed";
        state.error.fetchByUserId = action.payload;
      })

      // Create a new asset
      .addCase(createAsset.pending, (state) => {
        state.status.createAsset = "loading";
      })
      .addCase(createAsset.fulfilled, (state, action) => {
        state.status.createAsset = "succeeded";
        state.assets.push(action.payload);
      })
      .addCase(createAsset.rejected, (state, action) => {
        state.status.createAsset = "failed";
        state.error.createAsset = action.payload;
      })

      // Update an asset
      .addCase(updateAsset.pending, (state) => {
        state.status.updateAsset = "loading";
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        state.status.updateAsset = "succeeded";
        const index = state.assets.findIndex(
          (asset) => asset._id === action.payload._id
        );
        if (index !== -1) {
          state.assets[index] = action.payload;
        }
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.status.updateAsset = "failed";
        state.error.updateAsset = action.payload;
      })

      // Delete an asset
      .addCase(deleteAsset.pending, (state) => {
        state.status.deleteAsset = "loading";
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        state.status.deleteAsset = "succeeded";
        state.assets = state.assets.filter(
          (asset) => asset._id !== action.payload
        );
      })
      .addCase(deleteAsset.rejected, (state, action) => {
        state.status.deleteAsset = "failed";
        state.error.deleteAsset = action.payload;
      });
  },
});

export default assetSlice.reducer;
