// AuthSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {}, // Make sure this is defined
  userToken: null,
  isLoggedIn: false, // Initial state set to false
  userRole: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.userInfo = {
        id: action.payload.id,
        email: action.payload.email,
        role: action.payload.role,
        isLoggedIn: true,
        userToken: action.payload.token,
      };
      state.isLoggedIn = true;
      state.userToken = action.payload.token;
      state.userRole = action.payload.role;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userInfo = {};
      state.userToken = null;
      state.userRole = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
