import React from "react";
import { Link } from "react-router-dom";

const ActionMenu = ({ record, onEdit, onDelete }) => {
  return (
    <div className="dropdown dropdown-action text-end">
      <Link
        to="#"
        className="action-icon dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        <i className="material-icons">more_vert</i>
      </Link>
      <div className="dropdown-menu dropdown-menu-right">
        <Link
          className="dropdown-item"
          data-bs-toggle="modal"
          data-bs-target="#edit_asset"
          onClick={() => onEdit(record)}
        >
          <i className="fa fa-pencil m-r-5" /> Edit
        </Link>
        <Link
          className="dropdown-item"
          data-bs-toggle="modal"
          data-bs-target="#delete"
          onClick={() => onDelete(record)}
        >
          <i className="fa fa-trash m-r-5" /> Delete
        </Link>
      </div>
    </div>
  );
};

export default ActionMenu;
