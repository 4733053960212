import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector, useDispatch } from "react-redux";
import { fetchDailyReport } from "../../../../../store/slices/CallSlice";

const getMonthName = (monthIndex) => {
  const date = new Date();
  date.setMonth(monthIndex);
  return date.toLocaleString("default", { month: "long" });
};

const getStartAndEndDateOfMonth = (year, month) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

const Charts = () => {
  const dispatch = useDispatch();
  const { dailyReport } = useSelector((state) => state?.calls || {});
  const [chartData, setChartData] = useState([]);

  const fetchReportsForLastSevenMonths = async () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    let aggregatedMonthlyData = [];

    for (let i = 6; i >= 0; i--) {
      const monthToFetch = new Date(currentYear, currentMonth - i);
      const year = monthToFetch.getFullYear();
      const month = monthToFetch.getMonth();

      const { startDate, endDate } = getStartAndEndDateOfMonth(year, month);

      const result = await dispatch(fetchDailyReport({ startDate, endDate }));

      if (result?.payload && Array.isArray(result.payload)) {
        const aggregatedData = result.payload.reduce((acc, report) => {
          acc["Total Sales"] = (acc["Total Sales"] || 0) + report.totalCalls;
          acc["Total Revenue"] =
            (acc["Total Revenue"] || 0) + report.acceptedTransactionTotal;
          return acc;
        }, {});

        aggregatedMonthlyData.push({
          month: getMonthName(month),
          "Total Sales": aggregatedData["Total Sales"] || 0,
          "Total Revenue": aggregatedData["Total Revenue"] || 0,
        });
      } else {
        console.warn("Unexpected payload format or no data:", result.payload);
        aggregatedMonthlyData.push({
          month: getMonthName(month),
          "Total Sales": 0,
          "Total Revenue": 0,
        });
      }
    }

    return aggregatedMonthlyData;
  };

  useEffect(() => {
    const fetchData = async () => {
      const reports = await fetchReportsForLastSevenMonths();
      setChartData(reports);
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-12 col-lg-10 text-center mx-auto">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Sales Overview</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={chartData}
                      margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) =>
                          typeof value === "number" ? value.toFixed(2) : value
                        }
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Total Sales"
                        stroke="#ff9b44"
                        fill="#00c5fb"
                        strokeWidth={3}
                        dot={{ r: 3 }}
                        activeDot={{ r: 7 }}
                      />
                      <Line
                        type="monotone"
                        dataKey="Total Revenue"
                        stroke="#fc6075"
                        fill="#0253cc"
                        strokeWidth={3}
                        dot={{ r: 3 }}
                        activeDot={{ r: 7 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
