import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import AddProjectModalPopup from "../../../components/modelpopup/AddProjectModalPopup";
import SearchBox from "../../../components/SearchBox";
import {
  fetchAllProjects,
  fetchAllBids,
  createProject,
  updateProject,
  deleteProject,
} from "../../../store/slices/UpworkSlice";
import { toast } from "react-toastify";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import ActionMenu from "./ActionsMenu";
import Breadcrumbs from "../../../components/Breadcrumbs";

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [mode, setMode] = useState(null);
  const dispatch = useDispatch();
  const { projects, bids, status, error } = useSelector((state) => state?.upwork || {});

  useEffect(() => {
    dispatch(fetchAllProjects());
    dispatch(fetchAllBids());
  }, [dispatch]);

  // Handle adding a project
  const handleAddProject = async (projectData) => {
    try {
      await dispatch(createProject(projectData)).unwrap();
      await dispatch(fetchAllProjects()); // Refetch projects
      toast.success("Project added successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add project");
    }
  };

  // Handle updating a project
  const handleUpdateProject = async ({ id, projectData }) => {
    try {
      await dispatch(updateProject({ id, projectData })).unwrap();
      await dispatch(fetchAllProjects()); // Refetch projects
      toast.success("Project updated successfully");
      resetSelection();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update project");
    }
  };

  // Handle deleting a project
  const handleDelete = async () => {
    if (selectedProject) {
      try {
        await dispatch(deleteProject(selectedProject._id)).unwrap();
        await dispatch(fetchAllProjects()); // Refetch projects
        toast.success("Project deleted successfully");
        resetSelection();
      } catch (error) {
        toast.error(error.response?.data?.message || "Failed to delete project");
      }
    }
  };

  // Handle editing a project
  const handleEditProject = (project) => {
    setSelectedProject(project);
    setMode("edit");
  };

  // Handle deleting a project
  const handleDeleteProject = (project) => {
    setSelectedProject(project);
    document.getElementById("delete").click();
  };

  const resetSelection = () => {
    setSelectedProject(null);
    setMode(null);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Projects"
            title="Dashboard"
            subtitle="Projects"
            modal="#edit"
            name="Add Project"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns(handleEditProject, handleDeleteProject)}
                  dataSource={prepareProjectsForDisplay(projects)}
                  className="table-striped"
                  rowKey={(record) => record._id}
                  locale={getTableLocale(status, error)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddProjectModalPopup
        onSubmit={handleAddProject}
        onEdit={handleUpdateProject}
        editProject={selectedProject}
        mode={mode}
        bids={bids} // Pass bids for dropdown selection
      />
      <DeleteModal Name="Delete Project" onDeleteLeave={handleDelete} />
    </div>
  );
};

export default Projects;

// Helper to format projects for display
const prepareProjectsForDisplay = (projects) => {
  return projects?.map((project, index) => ({
    key: index + 1,
    id: index + 1,
    ...project,
    bidderName: `${project?.bidding?.bidder?.firstName || ""} ${
      project.bidding?.bidder?.lastName || ""
    }`,
    upworkId: `${project?.bidding?.upworkId || ""}`,
    jobPostingLink: `${project?.bidding?.jobPostingLink || ""}`,
    jobTitle: `${project?.bidding?.jobTitle || ""}`,
    connectNumber: `${project?.bidding?.connectNumber || ""}`,
    statusDisplay: project.status || "Active",
  }));
};

// Table columns
const columns = (handleEditProject, handleDeleteProject) => [
  { title: "#", dataIndex: "id", width: "5%" },
  { title: "Job Title", dataIndex: "jobTitle", width: "15%" },
  { title: "Job Posting Link", dataIndex: "jobPostingLink", width: "15%" },
  { title: "Client Name", dataIndex: "clientName", width: "15%" },
  { title: "Upwork Id", dataIndex: "upworkId", width: "15%" },
  { title: "Bidder", dataIndex: "bidderName", width: "15%" },
  { title: "Dialer Provided", dataIndex: "dialerProvided", width: "10%" },
  { title: "Contact Medium", dataIndex: "contactMedium", width: "10%" },
  { title: "Connect Used", dataIndex: "connectNumber", width: "10%" },
  { title: "Working Days", dataIndex: "workingDays", width: "10%" },
  { title: "Status", dataIndex: "statusDisplay", width: "10%" },
  { title: "Rate/Hour", dataIndex: "ratePerHour", width: "10%" },
  {
    title: "Action",
    className: "text-end",
    render: (_, record) => (
      <ActionMenu
        record={record}
        onEdit={handleEditProject}
        onDelete={handleDeleteProject}
      />
    ),
    width: "15%",
  },
];

// Utility for table locale (loading, errors, empty)
const getTableLocale = (status, error) => {
  return {
    emptyText: status?.fetchAllProjects === "loading" ? (
      <Loading />
    ) : error?.fetchAllProjects ? (
      <div className="text-center text-danger">
        <Error404 />
      </div>
    ) : (
      "No Data"
    ),
  };
};
