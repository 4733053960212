// src/utils/assetHelpers.js

import { toast } from "react-toastify";

// Helper to format accessories for display
export const formatAccessories = (accessories) => {
    return accessories?.length
      ? accessories.map((acc) => `${acc.type} (Serial: ${acc.serialNumber})`).join(", ")
      : "None";
  };
  
  // Helper to format assigned user for display
  export const formatAssignedUser = (assignedUser) => {
    return assignedUser?.firstName
      ? `${assignedUser?.firstName} ${assignedUser?.lastName}`
      : "Unassigned";
  };
  
  // Generic error handler
  export const handleError = (error, defaultMessage) => {
    const errorMessage = error.response?.data?.message || defaultMessage;
    toast.error(errorMessage);
  };
  
  // Function to filter assets based on filters (use in handleFilterChange)
  export const filterAssets = (asset, filters) => {
    const matchesId = filters.assetId
      ? asset.assetId?.toLowerCase().includes(filters.assetId.toLowerCase())
      : true;
    const matchesName = filters.assetName
      ? asset.name?.toLowerCase().includes(filters.assetName.toLowerCase())
      : true;
    const matchesUser = filters.assignedUser?.value
      ? asset?.assignedUser?._id === filters.assignedUser.value
      : true;
    const matchesStatus = filters.status ? asset.status === filters.status : true;
    return matchesId && matchesName && matchesUser && matchesStatus;
  };
  