import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import EditSalesFormTable from "./EditSalesFormTable.jsx";
const Fields = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Fields"
            title="Main"
            subtitle="Fields"
            modalClass="d-none col-auto float-end ms-auto"
          />

          <div className="row">
            <div className="col-md-12"></div>
          </div>
          <EditSalesFormTable />
        </div>
      </div>
    </div>
  );
};

export default Fields;
