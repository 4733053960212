import React, { useState, useEffect } from "react";
import { toast } from "react-toastify"; 

const AddProjectModalPopup = ({ onSubmit, onEdit, editProject, mode, bids }) => {
  const [clientName, setClientName] = useState("");
  const [status, setStatus] = useState("Active");
  const [agentId, setAgentId] = useState("");
  const [biddingId, setBiddingId] = useState("");
  const [dialerProvided, setDialerProvided] = useState("");
  const [contactMedium, setContactMedium] = useState("");
  const [workingDays, setWorkingDays] = useState(5);
  const [workingTime, setWorkingTime] = useState("");
  const [ratePerHour, setRatePerHour] = useState("");

  useEffect(() => {
    if (mode === "edit" && editProject) {
      setClientName(editProject?.clientName || "");
      setStatus(editProject?.status || "Active");
      setAgentId(editProject?.agent?._id || "");
      setBiddingId(editProject?.bidding?._id || "");
      setDialerProvided(editProject?.dialerProvided || "");
      setContactMedium(editProject?.contactMedium || "");
      setWorkingDays(editProject?.workingDays || 5);
      setWorkingTime(editProject?.workingTime || "");
      setRatePerHour(editProject?.ratePerHour || "");
    } else {
      resetForm();
    }
  }, [mode, editProject]);

  const resetForm = () => {
    setClientName("");
    setStatus("Active");
    setAgentId("");
    setBiddingId("");
    setDialerProvided("");
    setContactMedium("");
    setWorkingDays(5);
    setWorkingTime("");
    setRatePerHour("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      clientName,
      status,
      agentId,
      biddingId,
      dialerProvided,
      contactMedium,
      workingDays,
      workingTime,
      ratePerHour,
    };

    try {
      if (mode === "edit") {
        await onEdit({ id: editProject?._id, projectData: payload });
      } else {
        await onSubmit(payload);
      }
      resetForm();
      closeModal();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to save project";
      toast.error(errorMessage);
    }
  };

  const closeModal = () => {
    const modalId = "edit";
    const modalElement = document.getElementById(modalId);
    const closeButton = modalElement?.querySelector(".btn-close");
    if (closeButton) closeButton.click();
  };

  return (
    <div id="edit" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{
          maxWidth: window.innerWidth < 1024 ? "100%" : "40%",
          margin: "0 auto",
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {mode === "edit" ? "Edit Project" : "Add Project"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Client Name <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Status <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Active">Active</option>
                  <option value="Paused">Paused</option>
                  <option value="Ended">Ended</option>
                </select>
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Bidding Reference <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={biddingId}
                  onChange={(e) => setBiddingId(e.target.value)}
                >
                  <option value="">Select Bidding</option>
                  {bids?.map((bid) => (
                    <option key={bid._id} value={bid._id}>
                      {bid.jobTitle} - {bid.bidder.firstName} {bid.bidder.lastName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">Dialer Provided</label>
                <input
                  className="form-control"
                  type="text"
                  value={dialerProvided}
                  onChange={(e) => setDialerProvided(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">Contact Medium</label>
                <input
                  className="form-control"
                  type="text"
                  value={contactMedium}
                  onChange={(e) => setContactMedium(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Working Days <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={workingDays}
                  onChange={(e) => setWorkingDays(Number(e.target.value))}
                >
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                </select>
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">Working Time</label>
                <input
                  className="form-control"
                  type="text"
                  value={workingTime}
                  onChange={(e) => setWorkingTime(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">Rate per Hour</label>
                <input
                  className="form-control"
                  type="number"
                  value={ratePerHour}
                  onChange={(e) => setRatePerHour(e.target.value)}
                />
              </div>

              <div className="submit-section">
                <button className="btn btn-primary submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProjectModalPopup;
