import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { Applogo } from "../../../../../Routes/ImagePath";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const PaySlip = () => {
  const downloadPDF = () => {
    const element = document.getElementById("payslip-content");
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("payslip.pdf");
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="d-flex align-items-center">
          <Breadcrumbs
            maintitle="Payslip"
            title="Main"
            subtitle="Payslip"
            modal="#add_categories"
            name="Add Salary"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="col-auto float-end ms-auto">
            <div className="btn-group btn-group-sm">
              <button className="btn btn-white" onClick={downloadPDF}>
                <i className="fa fa-print fa-lg" /> Print
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card" id="payslip-content">
              <div className="card-body">
                <h4 className="payslip-title">
                  Payslip for the month of Feb 2019
                </h4>
                <div className="row">
                  <div className="col-sm-6 m-b-20">
                    <img src={Applogo} className="inv-logo" alt="Logo" />
                    <ul className="list-unstyled mb-0">
                      <li>Dreamguy's Technologies</li>
                      <li>3864 Quiet Valley Lane,</li>
                      <li>Sherman Oaks, CA, 91403</li>
                    </ul>
                  </div>
                  <div className="col-sm-6 m-b-20">
                    <div className="invoice-details">
                      <h3 className="text-uppercase">Payslip #49029</h3>
                      <ul className="list-unstyled">
                        <li>
                          Salary Month: <span>March, 2019</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 m-b-20">
                    <ul className="list-unstyled">
                      <li>
                        <h5 className="mb-0">
                          <strong>John Doe</strong>
                        </h5>
                      </li>
                      <li>
                        <span>Web Designer</span>
                      </li>
                      <li>Employee ID: FT-0009</li>
                      <li>Joining Date: 1 Jan 2023</li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h4 className="m-b-10">
                      <strong>Earnings</strong>
                    </h4>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Basic Salary</strong>
                            <span className="float-end">$6500</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>House Rent Allowance (H.R.A.)</strong>
                            <span className="float-end">$55</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Conveyance</strong>
                            <span className="float-end">$55</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Other Allowance</strong>
                            <span className="float-end">$55</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Total Earnings</strong>
                            <span className="float-end">
                              <strong>$55</strong>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-6">
                    <h4 className="m-b-10">
                      <strong>Deductions</strong>
                    </h4>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Tax Deducted at Source (T.D.S.)</strong>
                            <span className="float-end">$0</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Provident Fund</strong>
                            <span className="float-end">$0</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>ESI</strong>
                            <span className="float-end">$0</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Loan</strong>
                            <span className="float-end">$300</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Total Deductions</strong>
                            <span className="float-end">
                              <strong>$59698</strong>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12">
                    <p>
                      <strong>Net Salary: $59698</strong> (Fifty nine thousand
                      six hundred and ninety eight only.)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaySlip;
