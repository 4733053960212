import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../store/slices/AuthSlice";
import UsePostData from "../../../hooks/api/PostHook";
import { LuLogIn } from "react-icons/lu";
import { toast } from "react-toastify";
import logo from "../../../assets/img/logo.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "./login.css";

const Login = () => {
  const [user, setUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading, postData } = UsePostData(
    `${process.env.REACT_APP_BASE_URL}/auth/login`
  );
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const role = useSelector((state) => state?.auth?.userRole);
  let location = useLocation();
  const dispatch = useDispatch();

  // Redirect if already logged in
  if (isLoggedIn) {
    return (
      <Navigate
        to={
          role === "employee" || role === "supervisor"
            ? "/employee-dashboard"
            : role === "admin" || role === "manager" || role === "hr"
            ? "/admin-dashboard"
            : "/employees"
        }
        state={{ from: location }}
        replace
      />
    );
  }

  const changeHandler = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const loginHandler = async (event) => {
    event.preventDefault();
    postData(user)
      .then((result) => {
        const { user } = result.data;
        if (user && user.email && user.token) {
          const userData = {
            id: user._id,
            email: user.email,
            token: user.token,
            role: user.role,
          };
          dispatch(login(userData));
          navigate(
            user.role === "employee" ? "attendance-employee" : "employee"
          );
        } else {
          throw new Error("Login failed. Please check your credentials.");
        }
      })
      .catch((error) => {
        console.error("Login Error:", error);
        toast.error("Invalid Email and Password", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          theme: "colored",
        });
      });
  };

  return (
    <section className="auth">
      <div className="wrapper">
        <div className="form signup">
          <img
            src={logo}
            alt="Logo"
            style={{ width: "180px", height: "auto" }}
          />
          <form onSubmit={loginHandler} autoComplete="off">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                onChange={changeHandler}
                placeholder="admin@example.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div
                className="password-wrapper"
                style={{ position: "relative" }}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  name="password"
                  onChange={changeHandler}
                  placeholder="********"
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "66%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? (
                    <AiFillEyeInvisible size={20} />
                  ) : (
                    <AiFillEye size={20} />
                  )}
                </span>
              </div>
            </div>
            <button type="submit" className="form-control" disabled={isLoading}>
              {isLoading ? (
                "Loading..."
              ) : (
                <>
                  Login <LuLogIn />
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
