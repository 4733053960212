import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { objectToQueryParams } from "../utils";

const base_url = `${process.env.REACT_APP_BASE_URL}/api`;

// Thunks

// Fetch all bids
export const fetchAllBids = createAsyncThunk(
  "upwork/fetchAllBids",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.get(`${base_url}/bids`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data?.bids || [];
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to fetch bids"
      );
    }
  }
);

// Create a new bid
export const createBid = createAsyncThunk(
  "upwork/createBid",
  async (bidData, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.post(`${base_url}/bids`, bidData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data?.bid;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to create bid"
      );
    }
  }
);

// Update a bid
export const updateBid = createAsyncThunk(
  "upwork/updateBid",
  async ({ id, bidData }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.patch(`${base_url}/bids/${id}`, bidData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data?.bid;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to update bid"
      );
    }
  }
);

// Delete a bid
export const deleteBid = createAsyncThunk(
  "upwork/deleteBid",
  async (id, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      await axios.delete(`${base_url}/bids/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to delete bid"
      );
    }
  }
);

// Fetch bidder statistics
export const fetchBidderStatistics = createAsyncThunk(
  "upwork/fetchBidderStatistics",
  async ({ filter }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const params = {...filter }
      const token = auth.userToken;
      const response = await axios.get(`${base_url}/bidder-statistics?${objectToQueryParams(params)}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data || [];
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to fetch bidder statistics"
      );
    }
  }
);

// Fetch agent statistics
export const fetchAgentStatistics = createAsyncThunk(
  "upwork/fetchAgentStatistics",
  async ({ filter }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const params = {...filter }
      const token = auth.userToken;
      const response = await axios.get(`${base_url}/agent-statistics?${objectToQueryParams(params)}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data || [];
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to fetch agent statistics"
      );
    }
  }
);

// Fetch all projects
export const fetchAllProjects = createAsyncThunk(
  "upwork/fetchAllProjects",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.get(`${base_url}/projects`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data?.projects || [];
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to fetch projects"
      );
    }
  }
);
// Fetch all agents
export const fetchAllAgents = createAsyncThunk(
  "upwork/fetchAllAgents",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.get(`${base_url}/users?upworkRole=agent`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data?.users || [];
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to fetch agents"
      );
    }
  }
);

// Update tracking
export const updateTracking = createAsyncThunk(
  "upwork/updateTracking",
  async ({ id, trackingData }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.patch(
        `${base_url}/tracking/${id}`,
        trackingData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to update tracking"
      );
    }
  }
);

// Delete tracking
export const deleteTracking = createAsyncThunk(
  "upwork/deleteTracking",
  async (id, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      await axios.delete(`${base_url}/tracking/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to delete tracking"
      );
    }
  }
);

// Create a project
export const createProject = createAsyncThunk(
  "upwork/createProject",
  async (projectData, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.post(`${base_url}/projects`, projectData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data?.project;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to create project"
      );
    }
  }
);

// Update a project
export const updateProject = createAsyncThunk(
  "upwork/updateProject",
  async ({ id, projectData }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.patch(
        `${base_url}/projects/${id}`,
        projectData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data?.data?.project;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to update project"
      );
    }
  }
);

// Delete a project
export const deleteProject = createAsyncThunk(
  "upwork/deleteProject",
  async (id, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      await axios.delete(`${base_url}/projects/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to delete project"
      );
    }
  }
);
// Fetch all trackings
export const fetchAllTrackings = createAsyncThunk(
  "upwork/fetchAllTrackings",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.get(`${base_url}/tracking`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data || [];
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to fetch tracking data"
      );
    }
  }
);

// Create tracking
export const createTracking = createAsyncThunk(
  "upwork/createTracking",
  async (trackingData, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const token = auth.userToken;
      const response = await axios.post(`${base_url}/tracking`, trackingData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to create tracking"
      );
    }
  }
);
 // Update a user (e.g., role or other details)
export const updateUser = createAsyncThunk("upwork/updateUser", async ({ id, updates }, { rejectWithValue, getState }) => {
  try {
    const { auth } = getState();
    const token = auth.userToken;
    const response = await axios.patch(`${base_url}/users/${id}`, updates, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message || "Failed to update user");
  }
});
// Fetch users by department
export const fetchUsersByDepartment = createAsyncThunk("upwork/fetchUsersByDepartment", async (departmentId, { rejectWithValue, getState }) => {
  try {
    const { auth } = getState();
    const token = auth.userToken;
    const response = await axios.get(`${base_url}/users/upwork?department=${departmentId}&limit=100&status=active`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data?.data?.users || [];
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message || "Failed to fetch users by department");
  }
});
// Approve tracking
export const approveTracking = createAsyncThunk("upwork/approveTracking", async ({ id, approverId }, { rejectWithValue, getState }) => {
  try {
    const { auth } = getState();
    const token = auth.userToken;
    const response = await axios.post(`${base_url}/tracking/${id}/approve`, { approverId }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message || "Failed to approve tracking");
  }
});


// Slice
const initialState = {
  bids: [],
  agents: [],
  projects: [],
  trackings: [],
  agentStatistics: [],
  bidderStatistics: [],
  users: [],
  userStatus: "idle",
  agentsStatus: "idle",
  fetchBidderStatistics: "idle",
  fetchAgentStatistics: "idle",
  fetchAllTrackings: "idle",
  createTracking: "idle",
  updateTracking: "idle",
  deleteTracking: "idle",
  approveTracking: "idle",
  status: {
    fetchAllBids: "idle",
    fetchAllProjects: "idle",
    createBid: "idle",
    createProject: "idle",
    updateBid: "idle",
    updateProject: "idle",
    deleteBid: "idle",
    deleteProject: "idle",
  },
  error: null,
};

const upworkSlice = createSlice({
  name: "upwork",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Upwork Reducers
    builder
      .addCase(updateUser.fulfilled, (state, action) => {
        const updatedUser = action.payload;
        const index = state?.users?.findIndex(
          (user) => user?._id === updatedUser._id
        );
        if (index !== -1) {
          state.users[index] = updatedUser;
        }
      })
      .addCase(fetchUsersByDepartment.pending, (state) => {
        state.userStatus = "loading";
      })
      .addCase(fetchUsersByDepartment.fulfilled, (state, action) => {
        state.userStatus = "succeeded";
        state.users = action.payload;
      })
      .addCase(fetchUsersByDepartment.rejected, (state, action) => {
        state.userStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAllTrackings.pending, (state) => {
        state.status.fetchAllTrackings = "loading";
      })
      .addCase(fetchAllTrackings.fulfilled, (state, action) => {
        state.status.fetchAllTrackings = "succeeded";
        state.trackings = action.payload;
      })
      .addCase(fetchAllTrackings.rejected, (state, action) => {
        state.status.fetchAllTrackings = "failed";
        state.error = action.payload;
      })
      .addCase(createTracking.fulfilled, (state, action) => {
        state.status.createTracking = "succeeded";
        state.trackings.push(action.payload);
      })
      .addCase(updateTracking.fulfilled, (state, action) => {
        state.status.updateTracking = "succeeded";
        const index = state.trackings.findIndex(
          (tracking) => tracking._id === action.payload._id
        );
        if (index !== -1) state.trackings[index] = action.payload;
      })
      .addCase(deleteTracking.fulfilled, (state, action) => {
        state.status.deleteTracking = "succeeded";
        state.trackings = state.trackings.filter(
          (tracking) => tracking._id !== action.payload
        );
      })
      .addCase(approveTracking.fulfilled, (state, action) => {
        state.status.approveTracking = "succeeded";
        const index = state.trackings.findIndex(
          (tracking) => tracking._id === action.payload._id
        );
        if (index !== -1) state.trackings[index] = action.payload;
      })
      .addCase(fetchAgentStatistics.pending, (state) => {
        state.status.fetchAgentStatistics = "loading";
      })
      .addCase(fetchAgentStatistics.fulfilled, (state, action) => {
        state.status.fetchAgentStatistics = "succeeded";
        state.agentStatistics = action.payload;
      })
      .addCase(fetchAgentStatistics.rejected, (state, action) => {
        state.status.fetchAgentStatistics = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAllAgents.pending, (state) => {
        state.status.fetchAllAgents = "loading";
      })
      .addCase(fetchAllAgents.fulfilled, (state, action) => {
        state.status.fetchAllAgents = "succeeded";
        state.agents = action.payload;
      })
      .addCase(fetchAllAgents.rejected, (state, action) => {
        state.status.fetchAllAgents = "failed";
        state.error = action.payload;
      });

    // Bids
    builder
      // Fetch Bidder Statistics
      .addCase(fetchBidderStatistics.pending, (state) => {
        state.status.fetchBidderStatistics = "loading";
      })
      .addCase(fetchBidderStatistics.fulfilled, (state, action) => {
        state.status.fetchBidderStatistics = "succeeded";
        state.bidderStatistics = action.payload;
      })
      .addCase(fetchBidderStatistics.rejected, (state, action) => {
        state.status.fetchBidderStatistics = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAllBids.pending, (state) => {
        state.status.fetchAllBids = "loading";
      })
      .addCase(fetchAllBids.fulfilled, (state, action) => {
        state.status.fetchAllBids = "succeeded";
        state.bids = action.payload;
      })
      .addCase(fetchAllBids.rejected, (state, action) => {
        state.status.fetchAllBids = "failed";
        state.error = action.payload;
      })
      .addCase(createBid.fulfilled, (state, action) => {
        state.status.createBid = "succeeded";
        state.bids.push(action.payload);
      })
      .addCase(updateBid.fulfilled, (state, action) => {
        const index = state.bids.findIndex(
          (bid) => bid._id === action.payload._id
        );
        if (index !== -1) state.bids[index] = action.payload;
      })
      .addCase(deleteBid.fulfilled, (state, action) => {
        state.bids = state.bids.filter((bid) => bid._id !== action.payload);
      });

    // Projects
    builder
      .addCase(fetchAllProjects.pending, (state) => {
        state.status.fetchAllProjects = "loading";
      })
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        state.status.fetchAllProjects = "succeeded";
        state.projects = action.payload;
      })
      .addCase(fetchAllProjects.rejected, (state, action) => {
        state.status.fetchAllProjects = "failed";
        state.error = action.payload;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.status.createProject = "succeeded";
        state.projects.push(action.payload);
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        const index = state.projects.findIndex(
          (project) => project?._id === action.payload._id
        );
        if (index !== -1) state.projects[index] = action.payload;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.projects = state.projects.filter(
          (project) => project._id !== action.payload
        );
      });
  },
});

export default upworkSlice.reducer;
