import React from "react"; 

const DeleteModal = ({ onDeleteLeave, closeModal }) => {
  return (
    <>
      <div className="modal custom-modal fade" id="delete" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Confirmation</h3>
                <p>Are you sure you want to delete?</p>
              </div>
              <div className="modal-btn delete-action d-flex justify-content-center">
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-primary continue-btn"
                      onClick={onDeleteLeave}
                      data-bs-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-primary cancel-btn"
                      onClick={closeModal}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
